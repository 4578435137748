// Redux
import { ActionTypes } from "../actionTypes";
import { store } from "../../createStore";

export interface FetchConfirmedTimeElapsedInCurrentYear {
  type: ActionTypes.fetchConfirmedTimeElapsedInCurrentYear;
  paypload: number | 0;
}

export const setConfirmedTimeElapsedInCurrentYear = (
  time: number = 0
): FetchConfirmedTimeElapsedInCurrentYear => {
  return {
    type: ActionTypes.fetchConfirmedTimeElapsedInCurrentYear,
    paypload: time
  };
};

export const increaseConfirmedTimeElapsedInCurrentYear = (
  time: number = 0
): FetchConfirmedTimeElapsedInCurrentYear => {
  const currentTime = store.getState().confirmedTimeElapsedInCurrentYear;
  return setConfirmedTimeElapsedInCurrentYear(currentTime + time);
};
