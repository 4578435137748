import { action, ActionType } from "typesafe-actions";
import { ADD_ACTIVITY_SELECTED, SET_ID_BLOC_SELECTED } from "./types";
import { setItemLocalStorage } from "../../services/utility.service";

export const addActivitySelected = (indexArrayActivitySelected: string) => {
  setItemLocalStorage(ADD_ACTIVITY_SELECTED, indexArrayActivitySelected);
  return action(ADD_ACTIVITY_SELECTED, indexArrayActivitySelected);
};

export const setIdBlocSelected = (idBlocSelected: number | string) => {
  setItemLocalStorage(SET_ID_BLOC_SELECTED, idBlocSelected);
  return action(SET_ID_BLOC_SELECTED, idBlocSelected);
};

const allActions = {
  addActivitySelected,
  setIdBlocSelected
};

export type themcardAction = ActionType<typeof allActions>;
