import { Reducer } from "redux";
import { BlocAction } from "./actions";
import {
  SET_ACTIVE_PAGE_SELECTED,
  SET_BLOC_SELECTED,
  SET_HAVE_TO_RESET_VALUE,
  SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED,
  SET_OPENED_THE_CHILD_MODULE,
  UNSET_HAVE_TO_RESET_VALUE,
  UNSET_OPENED_THE_CHILD_MODULE
} from "./types";
import { getItemLocalStorage } from "../../services/utility.service";

export interface IModule {
  blocSelected: number;
  activePageSelected: number;
  haveToResetValue: boolean;
  openedTheChildModule: boolean;
  moduleActiveWhenBlocSelected: number | string;
}

const initialState: IModule = {
  blocSelected: getItemLocalStorage(SET_BLOC_SELECTED) || 0,
  activePageSelected: getItemLocalStorage(SET_ACTIVE_PAGE_SELECTED) || 0,
  haveToResetValue: getItemLocalStorage(SET_HAVE_TO_RESET_VALUE) || false,
  openedTheChildModule:
    getItemLocalStorage(SET_OPENED_THE_CHILD_MODULE) || false,
  moduleActiveWhenBlocSelected:
    getItemLocalStorage(SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED) || 0
};

const reducer: Reducer<any, BlocAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOC_SELECTED:
      return {
        ...state,
        blocSelected: action.payload
      };
    case SET_ACTIVE_PAGE_SELECTED:
      return {
        ...state,
        activePageSelected: action.payload
      };
    case SET_HAVE_TO_RESET_VALUE:
      return {
        ...state,
        haveToResetValue: action.payload
      };
    case UNSET_HAVE_TO_RESET_VALUE:
      return {
        ...state,
        haveToResetValue: action.payload
      };
    case SET_OPENED_THE_CHILD_MODULE:
      return {
        ...state,
        openedTheChildModule: action.payload
      };
    case UNSET_OPENED_THE_CHILD_MODULE:
      return {
        ...state,
        openedTheChildModule: action.payload
      };
    case SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED:
      return {
        ...state,
        moduleActiveWhenBlocSelected: action.payload
      };
    default:
      return state;
  }
};

export { reducer as ModuleReducer };
