// React
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

// Material ui
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";

// Redux
import { useDispatch } from "react-redux";

// Libs
import Scrollbars from "react-custom-scrollbars";
import { isAndroid } from "react-device-detect";
import Helmet from "react-helmet";

// Images
import icClose from "../../assets/ic-close.svg";
import logo from "../../assets/rf-e-learning-cac.png";

// Loader constant
import { CONST_LOADER } from "../../config/constantLoaders";

// Components
import ActivityPager from "../../features/ActivityPager/ActivityPager";
import Alert from "../../features/Alert/Alert";
import Lesson from "../../features/Lesson/Lesson";
import NextActivityBtn from "../../features/NextActivity/NextActivityBtn";
import Quiz from "../../features/Quiz/Quiz";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
import Timer from "../../features/Timer/Timer";
import YouTubePlayer from "../../features/Video/YouTubePlayer";

// Helpers
import {
  formatLessonSummaryContent,
  isLesson
} from "../../helpers/activity.helper";
import { getBackgroundTypeActivity } from "../../helpers/color-translator.helper";
import { formatDateForApi } from "../../helpers/date-fomatter.helper";
import { formatterTypeActivity } from "../../helpers/text-formatter.helper";
// Models
import { Activity } from "../../models/activity.model";
import { QuizActivity } from "../../models/quiz.model";
import { CreatedTime } from "../../models/time.model";

// Services
import { getActivity } from "../../services/navigation.service";
import { postTimeActivity } from "../../services/time.service";
import { setModuleValidationFlashMessage } from "../../services/flash.service";
import {
  increaseConfirmedTimeElapsedInCurrentYear,
  setActiveBloc
} from "../../store/actions";
// Colors
import { colorCAC } from "../../styles/color";
import { setErrorToHandleError } from "../../store/errorHandler/actions";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: "2001!important" as any
    },
    appBar: {
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("md")]: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    logo: {
      width: "200px"
    },
    headerTitle: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      }
    },
    headerTitleName: {
      textOverflow: "ellipsis",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "nowrap",
        overflow: "hidden"
      }
    },
    chipDomain: {
      color: "white",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0.5)
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15
      }
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7ebc4e",
        color: "#FFFFFF"
      }
    },
    chip: {
      borderRadius: 20,
      width: 105,
      backgroundColor: "white"
    },
    secondToolbar: {
      backgroundColor: "#f9fdfc"
    },
    headerSecondToolbar: {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    headerTitleResponsive: {
      whiteSpace: "nowrap"
    },
    activityContent: {
      backgroundColor: colorCAC.grayLight,
      padding: 16,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    textUp: {
      fontSize: 20,
      lineHeight: "normal",
      fontWeight: 600,
      color: "#9ea2aa",
      right: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderLeftWidth: 2,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: "white"
      }
    },
    textDown: {
      fontSize: 14,
      fontWeight: 600,
      color: colorCAC.black,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderRightWidth: 2,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        backgroundColor: "white"
      }
    },
    container: {
      width: 960,
      maxWidth: "100%",
      minHeight: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.up("md")]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 10
      }
    },
    containerVideo: {
      textAlign: "center",
      marginTop: 15
    },
    txtFinished: {
      color: colorCAC.greenRF
    },
    icFinished: {
      color: colorCAC.greenRF
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

/**
 * Route props
 */
interface IRouteInfo {
  id: string;
}

interface IRouteActivityLessonOrQuiz extends RouteComponentProps<IRouteInfo> {}

/**
 * ActivityLessonOrQuiz Component
 */
const ActivityLessonOrQuiz = (props: IRouteActivityLessonOrQuiz) => {
  /** Props */
  const { match, history } = props;
  /** Classes  */
  const classes = useStyles();
  /** Activity */
  const [activity, setActivity] = useState<Activity | QuizActivity>();
  /** FontSize */
  const [fontSize, setFontSize] = useState(16);
  /** ErrorMessage */
  const [errorMessage, setErrorMessage] = useState<string>();
  /** URL to redirect when activity is closed */
  const returnURL = match.url.substring(0, match.url.indexOf("/activite/"));
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Counter */
  const [counter, setCounter] = useState(0);
  /** Page */
  const [page, setPage] = useState(0);

  /**
   * activity unique Id
   */
  const activityUniqueId = match.params.id;

  const [handlerVideo, setHandlerVideo] = useState<boolean>(false);

  const [over, setOver] = useState<boolean>(false);
  /** Page percentage */
  const [pagePercentage, setPagePercentage] = useState(0);
  /** Expired */
  const expired = history.location.state
    ? (history.location.state as any)?.expired
    : !!activity?.expired;

  /** Hidden div to scroll into */
  const activityScrollTop = useRef(null);

  const isSendingTime = useRef(false);

  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /** Dispatch store */
  const dispatch = useDispatch();

  /**
   * Handle page navigation
   * @param pageValue
   */
  const handlePageNavigation = (pageValue: number): void => {
    setPage(pageValue);

    /** Scroll top when page changes */
    if (activityScrollTop.current) {
      setTimeout(() => {
        (activityScrollTop.current as any).parentNode.scrollTop = 0;
      }, 0);
    }

    if (
      activity &&
      isLesson(activity.uniqueId) &&
      (activity as Activity).contentList
    ) {
      setPagePercentage(
        (100 * pageValue) / (activity as Activity).contentList!.length
      );
    }
  };

  /** Is video */
  const isVideo: boolean = !!(activity && "video" === activity.type);

  /**
   * Handle close lesson
   */
  const handleCloseLesson = (): void => {
    history.push(returnURL);
  };

  /**
   * Increment size text
   */
  const incrementSizeText = (): void => {
    setFontSize(fontSize + 1);
  };

  /**
   * Decrement size text
   */
  const decrementSizeText = (): void => {
    setFontSize(fontSize - 1);
  };

  /**
   * Keycloak
   */
  const { fetchWithCredentials } = useUserAuthentication();

  /**
   * Send activity time
   */
  const sendActivityTime = (startDate: number) => {
    const endDate = Date.now();
    if (
      !isSendingTime.current &&
      !expired &&
      isLesson(activityUniqueId) &&
      endDate - startDate >= 1000
    ) {
      isSendingTime.current = true;
      postTimeActivity(
        formatDateForApi(startDate),
        formatDateForApi(endDate),
        activityUniqueId,
        fetchWithCredentials
      )
        .then(data => {
          isSendingTime.current = false;
          if (401 === data!.code) {
            return;
          }
          const result: CreatedTime = data.data;
          const seconds = result.diff;

          if (seconds) {
            if (result.module && result.module.completed) {
              setModuleValidationFlashMessage(result.module.name);
            }
            dispatch(increaseConfirmedTimeElapsedInCurrentYear(seconds));
          }
        })
        .catch(() => {
          enqueueSnackbar(
            "Une erreur s'est produite lors de l'enregistrement du temps",
            {
              variant: "error"
            }
          );
        });
    }
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    /** params */
    const { params } = match;

    /** Fetch data */
    const fetchData = async () => {
      setLoading(true);

      /** Load data */

      const result = await getActivity(params.id, fetchWithCredentials).catch(
        err => {
          dispatch(setErrorToHandleError(true, err.status));
        }
      );

      if (params.id) {
        const lesson = result.data;
        /** add necessary HTML class */
        if (lesson && lesson.contentList) {
          try {
            setErrorMessage("");
            lesson.contentList[0] = formatLessonSummaryContent(
              lesson.contentList[0]
            );
          } catch (e) {
            setErrorMessage(
              "Le sommaire de cette leçon n'a pas pu être formaté."
            );
          }
        }

        setActivity(lesson);
        setCounter(
          result.data.time.elapsed &&
            result.data.time.elapsed >= result.data.time.estimated
            ? result.data.time.estimated
            : result.data.time.elapsed
        );
      } else {
        setActivity(result.data);
      }

      setLoading(false);
    };

    let isSubscribed = true;
    if (isSubscribed) {
      fetchData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [match.params.id]);

  useEffect(() => {
    if (!isVideo && activity!) {
      setHandlerVideo(true);
    }
  }, [activity!]);

  /**
   * use Effect for counter video
   */
  useEffect(() => {
    if (activity && isLesson(activity!.uniqueId)) {
      handlePageNavigation(1);
    }

    // Update Current Bloc
    if (activity) {
      setActiveBloc(activity!.blocId ? activity!.blocId : 0);
    }

    return () => {};
  }, [activity!, handlerVideo]);

  useEffect(() => {
    if (isAndroid) {
      window.scrollTo(0, 1);
    }
  }, []);

  return (
    <Dialog
      fullScreen={true}
      open={true}
      keepMounted={true}
      className={classes.root}
    >
      {!loading && activity ? (
        <React.Fragment>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>{activity.name} - RF e-Learning CAC</title>
          </Helmet>

          <Hidden smDown={true}>
            <AppBar position="static" className={classes.appBar}>
              <Toolbar>
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true}>
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="rf-elearning"
                    />
                  </Grid>
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    {activity.type && !(activity as QuizActivity).started && (
                      <Chip
                        size="small"
                        label={formatterTypeActivity(activity.type)}
                        className={classes.chipDomain}
                        style={{
                          backgroundColor: getBackgroundTypeActivity(
                            activity.type
                          )
                        }}
                      />
                    )}
                    <Typography
                      variant="h5"
                      gutterBottom={true}
                      className={classes.headerTitleName}
                    >
                      {activity.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Grid
                      container={true}
                      alignItems="flex-start"
                      justify="flex-end"
                      direction="row"
                    >
                      {!(activity as QuizActivity).started && (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => handleCloseLesson()}
                        >
                          Quitter l'activité
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>

          <Hidden mdUp={true}>
            <AppBar position="static" className={classes.appBar}>
              <Grid container={true} direction="column">
                <Toolbar className={classes.headerSecondToolbar}>
                  <Grid container={true} alignItems="center">
                    <Grid item={true} xs={1}>
                      <NavLink to={returnURL}>
                        <img src={icClose} alt="close" />
                      </NavLink>
                    </Grid>
                    <Grid item={true} xs={11} className={classes.headerTitle}>
                      {activity.type && !(activity as QuizActivity).started && (
                        <Chip
                          size="small"
                          label={formatterTypeActivity(activity.type)}
                          className={classes.chipDomain}
                          style={{
                            backgroundColor: getBackgroundTypeActivity(
                              activity.type
                            )
                          }}
                        />
                      )}
                      <Scrollbars style={{ height: 40 }}>
                        <Typography
                          variant="h6"
                          gutterBottom={true}
                          className={classes.headerTitleResponsive}
                        >
                          {activity.name}
                        </Typography>
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Toolbar>
                {isLesson(activity.uniqueId) && (
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="baseline"
                    className={classes.secondToolbar}
                  >
                    {!expired && (
                      <Grid item={true} xs={9}>
                        <Timer
                          over={over}
                          counters={counter}
                          activityTime={activity.time.estimated}
                          isResponsive={true}
                          sendActiveTime={sendActivityTime}
                          canPlayTimer={handlerVideo}
                        />
                      </Grid>
                    )}
                    {!isVideo && (
                      <Grid item={true} xs={3}>
                        <Box m={1}>
                          <Grid
                            container={true}
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                          >
                            <ButtonGroup
                              size="medium"
                              variant="outlined"
                              className={classes.btgroup}
                            >
                              <Button
                                className={classes.textDown}
                                onClick={decrementSizeText}
                              >
                                A
                              </Button>
                              <Button
                                className={classes.textUp}
                                onClick={incrementSizeText}
                              >
                                A
                              </Button>
                            </ButtonGroup>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </AppBar>
          </Hidden>

          <DialogContent className={classes.activityContent}>
            <div ref={activityScrollTop} />

            <Hidden smDown={true}>
              {isLesson(activity.uniqueId) && (
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    <Grid
                      container={true}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      {!isVideo && (
                        <ButtonGroup
                          size="medium"
                          variant="outlined"
                          className={classes.btgroup}
                        >
                          <Button
                            className={classes.textDown}
                            onClick={decrementSizeText}
                          >
                            A
                          </Button>
                          <Button
                            className={classes.textUp}
                            onClick={incrementSizeText}
                          >
                            A
                          </Button>
                        </ButtonGroup>
                      )}
                      {!expired && (
                        <Timer
                          over={over}
                          counters={counter}
                          activityTime={activity.time.estimated}
                          isResponsive={false}
                          sendActiveTime={sendActivityTime}
                          canPlayTimer={handlerVideo}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={true} />
                </Grid>
              )}
            </Hidden>

            {isLesson(activity.uniqueId) ? (
              <Grid
                container={true}
                spacing={3}
                direction="column"
                justify={isVideo ? "flex-start" : "center"}
                alignItems="center"
                className={`${classes.container} ${isVideo &&
                  classes.containerVideo}`}
              >
                {isVideo && (activity as Activity).youtubeId && (
                  <Container maxWidth="md">
                    <YouTubePlayer
                      handleOver={() => setOver(true)}
                      videoId={(activity as Activity).youtubeId || ""}
                      handlePlayTimer={setHandlerVideo}
                    />
                    <NextActivityBtn nextActivityId={activity.nextActivityId} />
                  </Container>
                )}
                {(activity as Activity).contentList && (
                  <React.Fragment>
                    <Alert type="error">{errorMessage}</Alert>
                    <Lesson
                      activity={activity as Activity}
                      fontSize={fontSize}
                      page={page}
                    />
                    {(activity as Activity).type !== "video" && (
                      <Paper
                        elevation={3}
                        style={{
                          position:
                            navigator.platform === "iPad" || "iPhone"
                              ? "fixed"
                              : "absolute",
                          bottom: 0,
                          height: "70px",
                          left: 0,
                          right: 0
                        }}
                      >
                        <ActivityPager
                          pageNavigation={handlePageNavigation}
                          page={page}
                          totalPages={
                            (activity as Activity)!.contentList!.length
                          }
                          pagePercentage={pagePercentage}
                          nextActivityId={activity.nextActivityId!}
                        />
                      </Paper>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            ) : (
              <Grid
                container={true}
                spacing={3}
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
              >
                <Quiz
                  handleCloseLesson={handleCloseLesson}
                  quizActivity={activity as QuizActivity}
                />
              </Grid>
            )}
          </DialogContent>
        </React.Fragment>
      ) : (
        <SkeletonLoader
          height={880}
          loader={CONST_LOADER.ACTIVITY_LESSON_OR_QUIZZ}
        />
      )}
    </Dialog>
  );
};

export default withRouter(React.memo(ActivityLessonOrQuiz));
