import { VariantType } from "notistack";

const FLASH_LOCALSTORAGE_KEY = "flash";
const MODULE_VALIDATIO_KEY = "module_validation";

interface IFlashMessageOptions {
  duration?: number;
  variant?: VariantType;
}

interface IFlashMessage {
  content: string;
  duration: number;
  variant: VariantType;
}

interface IFlashMessages {
  [key: string]: IFlashMessage;
}

const storeFlashMessages = (messages: IFlashMessages) => {
  window.localStorage.setItem(FLASH_LOCALSTORAGE_KEY, JSON.stringify(messages));
};

const getAllFlashMessages = (): IFlashMessages => {
  return JSON.parse(
    window.localStorage.getItem(FLASH_LOCALSTORAGE_KEY) || "{}"
  );
};

export const setFlashMessage = (
  key: string,
  content: string,
  opts?: IFlashMessageOptions
) => {
  const { duration = 12000, variant = "info" } = opts || {};
  const messages = getAllFlashMessages();
  messages[key] = {
    content,
    duration,
    variant
  };

  storeFlashMessages(messages);
};

export const getFlashMessage = (key: string): IFlashMessage | null => {
  const messages = getAllFlashMessages();
  const flash = messages[key] || null;
  if (flash) {
    delete messages[key];
    storeFlashMessages(messages);
  }

  return flash;
};

export const setModuleValidationFlashMessage = (name: string) => {
  setFlashMessage(
    MODULE_VALIDATIO_KEY,
    `Votre attestation du module ${name} est disponible dans "Mes Attestations"`,
    {
      variant: "success"
    }
  );
};

export const getModuleValidationFlashMessage = (): IFlashMessage | null => {
  return getFlashMessage(MODULE_VALIDATIO_KEY);
};
