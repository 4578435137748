// React
import React from "react";

// Material
import { makeStyles, Paper, Theme } from "@material-ui/core";

// Styles
import "../../styles/activity.scss";

// Models
import { Activity } from "../../models/activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  backgroundContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginTop: 40,
    marginBottom: 140,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },
  secondgroundContainer: {
    backgroundColor: "#FFFFFF",
    width: "97%",
    margin: "0 auto",
    marginTop: -15,
    marginBottom: -15
  },
  foregroundContainer: {
    width: "97%",
    margin: "0 auto",
    marginTop: -10,
    position: "relative",
    display: "inline-block",
    left: "50%",
    overflow: "hidden",
    transform: "translateX(-50%)",
    marginBottom: -15
  },
  flipbookContainer: {
    width: "100% !important"
  }
}));

/**
 * Lesson props
 */
interface ILessonProps {
  activity: Activity;
  fontSize: number;
  page: number;
}
/**
 * Lesson component
 */
const Lesson = (props: ILessonProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Props */
  const { activity, fontSize, page } = props;

  /**
   * Remove useless space and other unicode char
   */
  const replaceSpace = (activityContent: string): string => {
    return activityContent.replace(/[\u200B-\u200D\uFEFF]/g, "");
  };

  return (
    <React.Fragment>
      {activity.type === "video" && activity.mediaLinks ? (
        activity.mediaLinks.map((link, index) =>
          link.media.data ? (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: link.media.data.html }}
            />
          ) : null
        )
      ) : (
        <Paper elevation={1} className={classes.backgroundContainer}>
          <Paper elevation={1} className={classes.secondgroundContainer}>
            <Paper
              elevation={1}
              className={classes.foregroundContainer}
              style={{ fontSize: `${fontSize}px` }}
            >
              <div
                className={`${activity.type}`}
                dangerouslySetInnerHTML={{
                  __html: replaceSpace(activity.contentList![page - 1])
                }}
              />
            </Paper>
          </Paper>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default React.memo(Lesson);
