// Redux
import { Dispatch } from "redux";
import { ActionTypes } from "../actionTypes";
import { store } from "../../createStore";
import { setItemLocalStorage } from "../../../services/utility.service";

export interface FetchActiveBloc {
  type: ActionTypes.fetchActiveBloc;
  paypload: number | 0;
}

export const setActiveBloc = (index: number = 0) => {
  setItemLocalStorage("activeBloc", index);
  store.dispatch<any>(async (dispatch: Dispatch) => {
    dispatch<FetchActiveBloc>({
      type: ActionTypes.fetchActiveBloc,
      paypload: index
    });
  });
};
