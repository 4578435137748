/**
 * titleCase modify string HELLO WORLD to Hello World
 * @param str
 */
export const titleCase = (str: string): string => {
  return str
    .split(" ")
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Format type activity label
 * @param str
 */
export const formatterTypeActivity = (str: string): string => {
  let labelFormatted = str;
  switch (str) {
    case "ficheInfo":
      labelFormatted = "Mémo";
      break;
    case "video":
      labelFormatted = "Vidéo";
      break;
    case "annonce":
      labelFormatted = "Annonce";
      break;
    case "quizThematique":
      labelFormatted = "Quiz thématique";
      break;
    case "quizFinal":
      labelFormatted = "Quiz final";
      break;
    case "categorisation":
      labelFormatted = "Catégorisation";
      break;
    case "analyse":
      labelFormatted = "Cas pratique";
      break;
    case "casPratique":
      labelFormatted = "Cas pratique";
      break;
  }
  return labelFormatted;
};

/**
 * Is plurial
 * @param nbActivity
 */
export const isPlurial = (nbActivity: number): string => {
  return nbActivity > 1 ? "activités" : "activité";
};

/**
 * return formatted description
 * @param moduleDescription
 * @param nbLines
 * @param endThreeDots
 */
export const getFormattedModuleDescription = (
  moduleDescription: string,
  nbLines: number = 2,
  endThreeDots: boolean = true
): string => {
  let formattedModuleDescription: string[] = moduleDescription.split(
    /<\/li>|<\/p>/
  );

  const descriptionEnd =
    formattedModuleDescription.length > nbLines && endThreeDots
      ? "\n" + "..."
      : "";
  return formattedModuleDescription.slice(0, nbLines).join("") + descriptionEnd;
};
