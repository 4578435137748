// React
import React, { useState } from "react";
// Lib
import moment from "moment";
// Material
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Hidden,
  Typography,
  withStyles
} from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
// Models
import iconAttestation from "../../assets/ic-attestations.svg";
import iconTimeGreen from "../../assets/ic-timer-green.svg";
import { Category } from "../../models/category.model";
import { User } from "../../models/user.model";
// Helpers
import { getSecondsFormatted } from "../../helpers/date-fomatter.helper";
// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";
// Colors
import { colorCAC } from "../../styles/color";
// Service
import { getCertificatePdf } from "../../services/navigation.service";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
// Store
import { store } from "../../store/createStore";
import clsx from "clsx";
import { isIPad13 } from "react-device-detect";
import { RESPONSIVE } from "../../config/responsive";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(0.5, 0.5),
      minHeight: "min-content"
    },
    carIpad: {
      padding: theme.spacing(0.5, 0.5),
      minHeight: "min-content",
      width: "569px !important",
      height: "160px !important"
    },
    cardLeftBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopRightRadius: "0.5em",
        borderBottomRightRadius: "0.5em"
      },
      [theme.breakpoints.down("sm")]: {
        borderBottomRightRadius: "0.5em",
        borderBottomLeftRadius: "0.5em"
      }
    },
    cardContainerBorder: {
      [RESPONSIVE.IPAD_3_AND_4_AND_PRO_9.LANDSCAPE]: {
        flexBasis: "75%!important",
        height: "100%"
      },
      [RESPONSIVE.IPAD_3_AND_4_AND_PRO_9.PORTRAIT]: {
        flexBasis: "100%!important"
      },
      "@supports (-webkit-touch-callout: none)": {}
    },
    cardRightBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderBottomLeftRadius: "0.5em"
      },
      [theme.breakpoints.down("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderTopRightRadius: "0.5em"
      }
    },
    cardContent: {
      "&:last-child": {
        paddingBottom: 10
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative"
      }
    },
    cardCheckbox: {
      position: "absolute",
      top: 10,
      right: 10
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.anthracite,
      padding: 5,
      [theme.breakpoints.up("xs")]: {
        marginRight: 10
      }
    },
    subTitle: {
      textAlign: "center",
      fontSize: 14
    },
    subTitleTime: {
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        color: "#7fbc4d"
      }
    },
    subTitleTimeBolder: {
      fontWeight: 600
    },
    chipType: {
      color: "white",
      fontWeight: 600
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#D8DBDC"
      },
      cursor: "pointer",
      width: "100%"
    },
    cardDownloadProgress: {
      cursor: "progress"
    },
    cardContentDownload: {
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed #bbbcbe"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed #bbbcbe"
      },
      padding: 5,
      "&:last-child": {
        paddingBottom: 5
      }
    },
    cocardeContainer: {
      display: "flex"
    },
    cocardeIcon: {
      height: 26
    }
  })
);

/**
 * Circular progress
 */
const ColorCircularProgress = withStyles({
  root: {
    color: "#000"
  }
})(CircularProgress);

/**
 * Form control label checkbox
 */
const CertificateFormControlLabel = withStyles({
  root: {
    marginRight: 0
  }
})(MuiFormControlLabel);

/**
 * Green checkbox
 */
const GreenCheckbox = withStyles({
  root: {
    color: colorCAC.greenRF,
    "&$checked": {
      color: colorCAC.greenRF
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/**
 * CertificateCardProps props
 */
interface ICertificateCardProps {
  category: Category;
  selectState: boolean;
  checked: boolean;

  addCertificateToDownloadList(certificateId: number): void;

  disabledCheckbox: (certificateId: number) => boolean;
}

/**
 * CertificateCard component
 */
const CertificateCard = (props: ICertificateCardProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  /** Props */
  const {
    category,
    selectState,
    checked,
    addCertificateToDownloadList,
    disabledCheckbox
  } = props;
  /** downloading state */
  const [downloading, setDownloading] = useState(false);
  /** downloaded state */
  const [downloaded, setDownloaded] = useState(false);
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** User */
  const user: User | null = store.getState().user;

  /**
   * get pdf url
   * @param certificateId
   */
  const getPdfUrl = async () => {
    const [year, month] = category.startDate.split("-");
    const pdfName: string = `Attestation-RF-e-Learning-CAC-${month}-${year}-${
      user!.firstname
    }-${user!.lastname}-${category.id}`;

    await getCertificatePdf(category.id, pdfName, fetchWithCredentials);
    setDownloading(false);
    setDownloaded(true);
    setTimeout(() => {
      setDownloaded(false);
    }, 2000);
  };

  return (
    <React.Fragment>
      <Grid
        container={true}
        item={true}
        xs={true}
        className={clsx({
          [classes.card]: !isIPad13,
          [classes.carIpad]: isIPad13
        })}
      >
        {selectState ? (
          <Hidden smDown={true}>
            <Grid item={true} md={1} xs={1} container={true}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={checked}
                    onChange={() => addCertificateToDownloadList(category.id)}
                    disabled={disabledCheckbox(category.id)}
                    value="checked"
                  />
                }
                label=""
              />
            </Grid>
          </Hidden>
        ) : null}
        <Grid
          item={true}
          xs={12}
          md={selectState ? 8 : 9}
          className={clsx({
            [classes.cardContainerBorder]: isIPad13
          })}
        >
          <Card className={classes.cardLeftBorder}>
            <CardContent className={classes.cardContent}>
              {selectState ? (
                <Hidden mdUp={true}>
                  <CertificateFormControlLabel
                    className={classes.cardCheckbox}
                    control={
                      <GreenCheckbox
                        checked={checked}
                        disabled={disabledCheckbox(category.id)}
                        onChange={() =>
                          addCertificateToDownloadList(category.id)
                        }
                        value="checked"
                      />
                    }
                    label=""
                  />
                </Hidden>
              ) : null}

              <Grid
                container={true}
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item={true} md={12}>
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="baseline"
                    justify={isUpMd ? "flex-start" : "space-between"}
                  >
                    <Grid item={true} xs="auto">
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontWeight="600"
                        color="textPrimary"
                        fontSize="body1.fontSize"
                      >
                        <span className={classes.title}>
                          Attestation {category.name}
                        </span>
                      </Box>
                    </Grid>
                    {category.approvalNumber !== null ? (
                      <Hidden mdUp={true}>
                        <Grid container={true} item={true}>
                          <Chip
                            size="small"
                            label="Homologué"
                            className={classes.chipType}
                            style={{ backgroundColor: colorCAC.orange }}
                          />
                        </Grid>
                      </Hidden>
                    ) : null}
                    <Grid item={true} xs={12} md={"auto"}>
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontSize={16}
                        component="span"
                        color="textPrimary"
                      >
                        Module terminé:&nbsp;
                      </Box>
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontSize={16}
                        component="span"
                        fontWeight="600"
                        color="textPrimary"
                      >
                        le {moment(category.endingFormationDate).format("L")}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} md={12}>
                  <Grid
                    container={true}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item={true} xs="auto">
                      <img src={iconTimeGreen} alt="icon-attestation" />
                    </Grid>
                    <Grid item={true} xs="auto">
                      <Grid container={true} direction="column">
                        <Box marginBottom={1} marginTop={1}>
                          <Grid item={true} xs={12}>
                            <Typography
                              className={classes.subTitle}
                              color="textSecondary"
                              gutterBottom={false}
                              component="span"
                            >
                              Temps de formation réalisé&nbsp;
                            </Typography>
                            <Typography
                              className={`${classes.subTitleTime} ${classes.subTitleTimeBolder}`}
                              gutterBottom={false}
                              component="span"
                            >
                              {getSecondsFormatted(category.time.confirmed)}
                            </Typography>
                          </Grid>
                          <Grid item={true} xs={12}>
                            <Typography
                              className={classes.subTitle}
                              color="textSecondary"
                              gutterBottom={false}
                              component="span"
                            >
                              Temps du module&nbsp;
                            </Typography>
                            <Typography
                              className={classes.subTitleTime}
                              gutterBottom={false}
                              component="span"
                            >
                              {getSecondsFormatted(category.time.estimated)}
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    {category.approvalNumber !== null ? (
                      <Hidden smDown={true}>
                        <Grid item={true} xs="auto">
                          <Chip
                            size="small"
                            label="Homologué"
                            className={classes.chipType}
                            style={{ backgroundColor: colorCAC.orange }}
                          />
                        </Grid>
                      </Hidden>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item={true} xs={12} md={3} container={true}>
          <Card
            className={`${classes.cardDownload} ${classes.cardRightBorder} ${
              downloading ? classes.cardDownloadProgress : ""
            }`}
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              if (!downloading) {
                setDownloading(true);
                getPdfUrl();
              }
            }}
          >
            <Grid
              container={true}
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.cardContentDownload}
            >
              <Grid item={true} md={2} className={classes.cocardeContainer}>
                <ListItemIcon>
                  {downloading ? (
                    <ColorCircularProgress size={17} thickness={5} />
                  ) : downloaded ? (
                    <OfflinePinIcon />
                  ) : (
                    <img
                      src={iconAttestation}
                      alt="icon-attestation"
                      className={classes.cocardeIcon}
                    />
                  )}
                </ListItemIcon>
              </Grid>
              <Grid item={true} md={8}>
                <Typography
                  className={classes.subTitle}
                  color="textPrimary"
                  gutterBottom={false}
                >
                  {downloading
                    ? `Téléchargement...`
                    : downloaded
                    ? `Téléchargé !`
                    : `Télécharger l’attestation`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(CertificateCard);
