// React
import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router";

// Material ui
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Fab,
  Box
} from "@material-ui/core";

// Libs
import Helmet from "react-helmet";
import { useSnackbar } from "notistack";

// Icons
import { ArrowForward } from "@material-ui/icons";

// Models
import { AskContact } from "../../models/askContact.model";
import { MessageContact } from "../../models/messageContact.model";

// Services
import {
  getAsksContact,
  postMessageContact
} from "../../services/navigation.service";

// Color
import { colorCAC } from "../../styles/color";

// Components
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenDark,
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: "bold"
    },
    paper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    textField: {
      width: "100%"
    },
    formControl: {
      minWidth: 150,
      width: "100%"
    },
    pagerNavigation: {
      transform: "translateY(-50%)"
    },
    button: {
      color: "white",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        margin: 10
      }
    }
  })
);

/**
 * Contact Component
 */
const Contact = () => {
  /**
   * Keycloak
   */
  const { fetchWithCredentials } = useUserAuthentication();

  /** Classes  */
  const classes = useStyles();

  /** askList  */
  const [askList, setAskList] = useState<AskContact[]>([]);

  /** Contact input value state */
  const [email, setEmail] = useState("");
  const [ask, setAsk] = useState("");
  const [message, setMessage] = useState("");

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * useEffect fetchAsks
   */
  useEffect(() => {
    const fetchAsks = async () => {
      const result = await getAsksContact(fetchWithCredentials);
      setAskList(result.data);
      setLabelWidth(inputLabel.current!.offsetWidth);
    };
    fetchAsks();
  }, []);

  /**
   * Handle change email
   */
  const handleChangeContactEmail = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEmail(event.target.value as string);
  };

  /**
   * Handle change contact type
   */
  const handleChangeContactType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setAsk(event.target.value as string);
  };

  /**
   * Handle change message
   */
  const handleChangeContactMessage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setMessage(event.target.value as string);
  };

  /**
   * Send contact message
   */
  const sendMessage = () => {
    if (email === "" || ask === "" || message === "") {
      enqueueSnackbar("Veuillez remplir tous les champs", {
        variant: "warning"
      });
      return;
    }

    // Check for a valid email
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      enqueueSnackbar("Veuillez saisir une adresse email valide", {
        variant: "warning"
      });
      return;
    }

    // Send message
    const messageContact: MessageContact = {
      email,
      ask: askList!.find(element => element!.value === ask)!.id,
      message
    };
    postMessageContact(messageContact, fetchWithCredentials)
      .then(() => {
        setEmail("");
        setMessage("");
        setAsk("");
        enqueueSnackbar(`Message envoyé.`, {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar(`Erreur d'envoi. Veuillez réessayer plus tard.`, {
          variant: "error"
        });
      });
  };

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Contactez-nous - RF e-Learning CAC</title>
      </Helmet>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {askList && askList.length > 0 ? (
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={"auto"}>
                    <Box fontSize="h6.fontSize" className={classes.title}>
                      CONTACTEZ-NOUS
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Email"
                      className={classes.textField}
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                      value={email}
                      onChange={handleChangeContactEmail}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      margin="normal"
                    >
                      <InputLabel
                        ref={inputLabel}
                        htmlFor="outlined-contact-type-simple"
                      >
                        Nature de la demande
                      </InputLabel>
                      <Select
                        value={ask}
                        onChange={handleChangeContactType}
                        input={
                          <OutlinedInput
                            labelWidth={labelWidth}
                            name="contact-type"
                            id="outlined-contact-type-simple"
                          />
                        }
                      >
                        {askList.map((option: AskContact) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-message-input"
                      label="Message"
                      className={classes.textField}
                      type="text"
                      name="message"
                      margin="normal"
                      variant="outlined"
                      multiline
                      rows="4"
                      value={message}
                      onChange={handleChangeContactMessage}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ) : (
              <SkeletonLoader height={400} />
            )}
          </Grid>
        </Grid>
        {askList && askList.length > 0 && (
          <Grid item={true} xs={true} className={classes.pagerNavigation}>
            <Grid container={true} justify="center" alignItems="center">
              <Fab
                variant="extended"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => sendMessage()}
              >
                Envoyer&nbsp;
                <ArrowForward />
              </Fab>
            </Grid>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
};

export default withRouter(React.memo(Contact));
