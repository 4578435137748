// React
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

// Material ui
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Box,
  Typography,
  Divider,
  InputLabel
} from "@material-ui/core";

//Component
import GreenCheckbox from "../../features/GreenCheckbox/GreenCheckbox";

// Libs
import Helmet from "react-helmet";
import moment from "moment";
import { useUserAuthentication } from "@dsk-lib/user";

// Models
import { User, ISubscriptionPeriod } from "../../models/user.model";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";

//Service
import {
  changeUserNewsletter,
  getUserNewsletter
} from "../../services/user.service";

// Color
import { colorCAC } from "../../styles/color";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    titleContainer: {
      marginBottom: theme.spacing(2)
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenKaitoke,
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    subTitle: {
      color: colorCAC.black,
      textAlign: "left"
    },
    paper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      marginBottom: theme.spacing(5),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    subscriptionSperator: {
      borderBottom: "0.5px solid #c3c6cc"
    },
    dateLabel: {
      textAlign: "left",
      fontSize: 14,
      color: "#8a8a8a"
    },
    dateValue: {
      textAlign: "right",
      fontSize: 14,
      color: colorCAC.black
    },
    textField: {
      width: "100%"
    },
    label: {
      textAlign: "left",
      overflowWrap: "break-word",
      width: "65%",
      lineHeight: "1.5",
      marginTop: "9px",
      color: "#575b66",
      fontWeight: 600
    },
    textFieldInput: {
      color: `${colorCAC.black} !important`
    }
  })
);

/**
 * Profil Component
 */
const Profil = () => {
  /** Classes  */
  const classes = useStyles();

  /**Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);

  /**
   * Change User Newsletter
   */
  const [checked, setChecked] = useState(true);

  const checkNewsletter = (): void => {
    changeUserNewsletter(fetchWithCredentials, !checked);
    setChecked(!checked);
  };

  /**User subscription to Newsletter*/
  useEffect(() => {
    getUserNewsletter(fetchWithCredentials).then(res =>
      setChecked(res === true)
    );
  }, []);

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mon profil - RF e-Learning CAC</title>
      </Helmet>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {user && (
              <React.Fragment>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    spacing={3}
                    className={classes.titleContainer}
                  >
                    <Grid item xs={"auto"}>
                      <Box fontSize="h6.fontSize" className={classes.title}>
                        Mes coordonnées
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography className={classes.subTitle} component="p">
                        La page Profil vous permet de consulter vos informations
                        personnelles. Pour toute modification, contactez le
                        Service Relation Client au : 01 48 00 59 66 du lundi au
                        vendredi de 9h00 à 18h00 ou src@grouperf.com
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.subTitle} component="p">
                        Les informations communiquées sont uniquement destinées
                        au Groupe Revue Fiduciaire.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        disabled={true}
                        label="Email"
                        className={classes.textField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        value={user.email}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="outlined-company-input"
                        disabled={true}
                        label="Société"
                        className={classes.textField}
                        type="company"
                        name="company"
                        autoComplete="company"
                        margin="normal"
                        variant="outlined"
                        value={user.company}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="outlined-lastname-input"
                        disabled={true}
                        label="Nom"
                        className={classes.textField}
                        type="lastname"
                        name="lastname"
                        autoComplete="lastname"
                        margin="normal"
                        variant="outlined"
                        value={user.lastname}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        id="outlined-firstname-input"
                        disabled={true}
                        label="Prénom"
                        className={classes.textField}
                        type="firstname"
                        name="firstname"
                        autoComplete="firstname"
                        margin="normal"
                        variant="outlined"
                        value={user.firstname}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-firstname-input"
                        disabled={true}
                        label="Adresse"
                        className={classes.textField}
                        type="address"
                        name="address"
                        autoComplete="address"
                        margin="normal"
                        variant="outlined"
                        value={user.address}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        id="outlined-postal-input"
                        disabled={true}
                        label="Code postal"
                        className={classes.textField}
                        type="postal"
                        name="postal"
                        autoComplete="postal"
                        margin="normal"
                        variant="outlined"
                        value={user.postal}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        id="outlined-city-input"
                        disabled={true}
                        label="Ville"
                        className={classes.textField}
                        type="city"
                        name="city"
                        autoComplete="city"
                        margin="normal"
                        variant="outlined"
                        value={user.city}
                        InputProps={{
                          className: classes.textFieldInput
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    spacing={3}
                    className={classes.titleContainer}
                  >
                    <Grid item xs={"auto"}>
                      <Box fontSize="h6.fontSize" className={classes.title}>
                        Mes abonnements
                      </Box>
                    </Grid>
                  </Grid>
                  {user.allSubscriptionPeriods
                    .map(
                      (
                        subscriptionPeriod: ISubscriptionPeriod,
                        index: number
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Grid container spacing={3}>
                              <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    className={classes.dateLabel}
                                    component="p"
                                  >
                                    Date de début
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className={classes.dateValue}
                                    style={{
                                      fontWeight:
                                        index ===
                                        user.allSubscriptionPeriods.length - 1
                                          ? 800
                                          : 500
                                    }}
                                    component="p"
                                  >
                                    {moment(subscriptionPeriod.start).format(
                                      "L"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                justify="space-between"
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    className={classes.dateLabel}
                                    component="p"
                                  >
                                    Date de fin
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className={classes.dateValue}
                                    style={{
                                      fontWeight:
                                        index ===
                                        user.allSubscriptionPeriods.length - 1
                                          ? 800
                                          : 500
                                    }}
                                    component="p"
                                  >
                                    {moment(subscriptionPeriod.end).format("L")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Box mt={2} mb={2}>
                              <Divider light />
                            </Box>
                          </React.Fragment>
                        );
                      }
                    )
                    .reverse()}
                </Paper>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    spacing={3}
                    className={classes.titleContainer}
                  >
                    <Grid item xs={"auto"}>
                      <Box fontSize="h6.fontSize" className={classes.title}>
                        Mes Alertes
                      </Box>
                    </Grid>
                  </Grid>
                  <Box component="div" display="flex" alignItems="flex-start">
                    <GreenCheckbox
                      onChange={checkNewsletter}
                      checked={checked}
                    />
                    <InputLabel className={classes.label}>
                      J'autorise la Revue Fiduciaire à m'envoyer des
                      notifications par e-mail afin de m'avertir qu'un module
                      non terminé à 100% arrive bientôt à la fin de sa validité.{" "}
                    </InputLabel>
                  </Box>
                </Paper>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(React.memo(Profil));
