import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";
// Lib
import { NavLink } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
import { AutoSizer } from "react-virtualized";
import { useSnackbar } from "notistack";
// Material
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Toolbar,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// Style
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
// Images
import arrowdown from "../../assets/arrow-down.svg";
import logo from "../../assets/rf-e-learning-cac.png";
// Components
import ActivityLessonOrQuiz from "../ActivityLessonOrQuiz/ActivityLessonOrQuiz";
import Certificate from "../Certificate/Certificate";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion/FrequentlyAskedQuestion";
import Home from "../Home/Home";
import Menu from "../Menu/Menu";
import Module from "../Module/Module";
import Modules from "../Modules/Modules";
import NoMatch from "../NoMatch/NoMatch";
import Profil from "../Profil/Profil";
import Contact from "../Contact/Contact";
import Unauthorized from "../Unauthorized/Unauthorized";
import Mention from "../Mention/Mention";
import OverlayLoader from "../Loader/OverlayLoader";
// Services
import { disconnectUser, getUsername } from "../../services/user.service";
import { getTimeElapsedInCurrentYear } from "../../services/navigation.service";
import { isIE } from "../../services/browser.service";
// Providers
import {
  useScrollDispatch,
  useScrollState
} from "../../shared/Scroll.provider";
// Hoc
import WithErrorHandler from "../../hoc/WithErrorHandler/WithErrorHandler";
// Models
import { User } from "../../models/user.model";
// Store
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";
import {
  fetchUser,
  setConfirmedTimeElapsedInCurrentYear
} from "../../store/actions";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { store } from "../../store/createStore";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

//Config
import { CONST } from "../../config/constant";
import SurveyModal from "../../features/SurveyModal/SurveyModal";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    labelUsername: {
      cursor: "pointer"
    },
    username: {
      fontWeight: 600
    },
    dropArrow: {
      height: 13,
      width: 13,
      cursor: "pointer"
    },
    paper: {
      position: "absolute",
      top: 55,
      width: 190,
      padding: 10,
      cursor: "pointer",
      display: "none"
    },
    hide: {
      display: "block"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    logoContainer: {
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center"
      }
    },
    logoLink: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center"
    },
    appBar: {
      zIndex: 2000,
      backgroundColor: "#ffffff",
      marginLeft: drawerWidth,
      width: `100%`,
      justifyContent: "center"
    },
    logo: {
      width: 200,
      [theme.breakpoints.down("xs")]: {
        width: "180px"
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    drawerPaper: {
      width: drawerWidth,
      overflowY: "hidden"
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(0)
    },
    scroller: {
      padding: theme.spacing(3)
    },
    content: {
      margin: "0 auto",
      minWidth: "90%",
      height: "calc(100vh  - 50px)",
      backgroundColor: "#F1F4F5"
    },
    toolbar: theme.mixins.toolbar,
    headerContent: {
      [theme.breakpoints.up("md")]: {
        marginTop: 40,
        marginBottom: 40
      }
    },
    snackbarButton: {
      color: "white",
      borderColor: "white",
      margin: 5
    }
  })
);

/**
 * Header username props
 */
interface IHeaderUsernameProps {
  username: string;
  isSuperAdmin: boolean;
}

/**
 * Header username component
 */
const HeaderUsername: React.FC<IHeaderUsernameProps> = ({
  username,
  isSuperAdmin
}: IHeaderUsernameProps) => {
  const { keycloak } = useUserAuthentication();
  /** Classes */
  const classes = useStyles();
  /** disconnection open State */
  const [disconnectionOpen, setDisconnectionOpen] = useState(false);

  /** wrapperRef */
  const wrapperRef = useRef(null);
  /** wrapperRefImg */
  const wrapperRefImg = useRef(null);
  /** wrapperRefDisconnection */
  const wrapperRefDisconnection = useRef(null);
  /**
   * Hook that alerts clicks outside of the passed ref
   * @param refLabel
   * @param refImg
   * @param refDisconnection
   */
  const useOutsideAlerter = (
    refLabel: any,
    refImg: any,
    refDisconnection: any
  ) => {
    /**
     * Hide Disconnection if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        refLabel.current &&
        !refLabel.current.contains(event.target) &&
        refImg.current &&
        !refImg.current.contains(event.target) &&
        refDisconnection.current &&
        !refDisconnection.current.contains(event.target)
      ) {
        setDisconnectionOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  useOutsideAlerter(wrapperRef, wrapperRefImg, wrapperRefDisconnection);

  return (
    <Grid
      id="usernameHeader"
      container={true}
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Box
        p={1}
        className={classes.labelUsername}
        onClick={() => setDisconnectionOpen(!disconnectionOpen)}
      >
        <Typography
          ref={wrapperRef}
          variant={"subtitle2"}
          className={classes.username}
        >
          {username}
        </Typography>
        {isSuperAdmin && (
          <Typography ref={wrapperRef} variant={"caption"}>
            Super Admin
          </Typography>
        )}
      </Box>
      <img
        ref={wrapperRefImg}
        src={arrowdown}
        alt="arrow-down-icon"
        onClick={() => setDisconnectionOpen(!disconnectionOpen)}
        className={classes.dropArrow}
      />
      <Paper
        ref={wrapperRefDisconnection}
        className={`${classes.paper} ${
          disconnectionOpen ? classes.hide : null
        } `}
        onClick={() => disconnectUser(keycloak)}
      >
        <Typography variant={"body2"}>Déconnexion</Typography>
      </Paper>
    </Grid>
  );
};

/**
 * App component
 */
const App = () => {
  /** Classes */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  /** Mobile open State */
  const [mobileOpen, setMobileOpen] = useState(false);
  /** username */
  const [username, setUsername] = useState<string>("");
  /** set month module sticky header */
  const [stickyTabs, setStickyTabs] = useState(false);

  /** Time elapsed in current year state */
  const confirmedTimeElapsedInCurrentYear = useSelector(
    (state: StoreState) => state.confirmedTimeElapsedInCurrentYear
  );

  /** scrollPositionSelected */
  const { scrollPositionSelected } = useScrollState();
  const dispatchScroll = useScrollDispatch();
  /** use snackbar */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /** Browser is internet explorer <= 11 */
  const isInternetExplorer: boolean = isIE();
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Dispatch store */
  const dispatch = useDispatch<ThunkDispatch<StoreState, any, Action>>();
  /** User unauthorized */
  const [userUnauthorized, setUserUnauthorized] = useState<boolean>(false);
  /** Is super admin */
  const isSuperAdmin: boolean = !!(user && user.superAdmin);

  const [loading, setLoading] = useState(true);

  /** Keycloak */
  const {
    authentication,
    keycloak,
    fetchWithCredentials
  } = useUserAuthentication();
  /** Use effect to hide sticky tabs when main scroll position = 0 */
  useEffect(() => {
    if (0 === scrollPositionSelected) {
      setStickyTabs(false);
    }
  }, [scrollPositionSelected]);

  /** Use effect to reset sticky tabs and scroll position when view is changed */
  useEffect(() => {
    setStickyTabs(false);
    dispatchScroll({
      type: "setScrollPosition",
      scrollPosition: 0
    });
  }, [window.location.pathname]);

  /** Use effect user */

  useEffect(() => {
    const initUser = async () => {
      if (user) {
        setUsername(getUsername(keycloak));
        fetchDataYear();
      }
    };

    const fetchDataYear = async () => {
      const result = await getTimeElapsedInCurrentYear(
        new Date().getFullYear(),
        fetchWithCredentials
      );
      dispatch(setConfirmedTimeElapsedInCurrentYear(result.data.confirmedTime));
    };

    let isSubscribed = true;
    if (isSubscribed) {
      initUser();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [user]);

  useEffect(() => {
    if (isInternetExplorer) {
      // customized actions
      const action = () => (
        <div
          style={{
            margin: "auto"
          }}
        >
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open(
                "https://www.google.com/intl/fr_fr/chrome/",
                "_blank"
              );
            }}
          >
            Télécharger Chrome
          </Button>
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open(
                "https://www.microsoft.com/fr-fr/windows/microsoft-edge",
                "_blank"
              );
            }}
          >
            Télécharger Edge
          </Button>
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open("https://www.mozilla.org/fr/firefox/", "_blank");
            }}
          >
            Télécharger Firefox
          </Button>
          <Button
            className={classes.snackbarButton}
            onClick={() => {
              closeSnackbar();
            }}
          >
            Ignorer
          </Button>
        </div>
      );

      enqueueSnackbar("Attention, vous utilisez un navigateur obsolète.", {
        variant: "warning",
        persist: true,
        action
      });
    }
  }, [isInternetExplorer]);

  /** Use effect */
  useEffect(() => {
    async function getResultFetch() {
      try {
        await dispatch(fetchUser(fetchWithCredentials));
        if (!store.getState().user) {
          setUserUnauthorized(true);
        }
      } catch (error) {
        if (error?.status === 403) {
          setUserUnauthorized(true);
        } else {
          dispatch(setErrorToHandleError(true));
        }
      }
    }

    // Todo: Remove this code line when @dsk-lib/user will be updated
    keycloak &&
      (keycloak.onAuthRefreshError = () => {
        keycloak.login();
      });

    if (!authentication.isPending && !authentication.isAuthenticated) {
      const referrer = localStorage.getItem("referrer") || "";
      if (
        (!CONST.PAGE_PRODUCTLIST.includes(referrer) || !referrer) &&
        !process.env.REACT_APP_LOCK_PORTAL_REDIRECTION
      ) {
        window.location.replace(CONST.PAGE_PRODUCTLIST);
        return;
      }
      localStorage.removeItem("referrer");
      keycloak && keycloak.login();
    } else if (!authentication.isPending) {
      setLoading(false);
      getResultFetch();
    }
  }, [authentication.isAuthenticated, authentication.isPending]);

  if (
    document.getElementsByClassName(
      "ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY"
    )[0]
  ) {
    // tslint:disable-next-line: max-line-length
    (document.getElementsByClassName(
      "ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY"
    )[0] as HTMLElement).style.backgroundColor = "#CCCCCC";
  }
  if (
    document.getElementsByClassName(
      "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
    )[0]
  ) {
    // tslint:disable-next-line: max-line-length
    (document.getElementsByClassName(
      "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
    )[0] as HTMLElement).style.width = "8px";
    (document.getElementsByClassName(
      "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
    )[0] as HTMLElement).style.top = "0px";
  }

  /**
   * Handle drawer toggle
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getScrollPositionSelected = (scrollValues: any) => {
    const tabs = document.getElementById("tabsContainer");
    if (tabs && tabs.offsetTop + tabs.clientHeight < scrollValues.scrollTop) {
      setStickyTabs(true);
      if (scrollPositionSelected < tabs.offsetTop + tabs.clientHeight) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: tabs.offsetTop + tabs.clientHeight + 1
        });
      }
    } else if (
      tabs &&
      tabs.offsetTop + tabs.clientHeight > scrollValues.scrollTop
    ) {
      setStickyTabs(false);
      if (scrollPositionSelected > tabs.offsetTop + tabs.clientHeight) {
        dispatchScroll({
          type: "setScrollPosition",
          scrollPosition: 0
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <OverlayLoader />
      ) : (
        <>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar} color="default">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logoContainer}>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/home`}
                  className={classes.logoLink}
                >
                  <img
                    src={logo}
                    className={classes.logo}
                    alt="rf-elearning-cac"
                  />
                </NavLink>
              </div>
              <Hidden smUp={true}>
                <Box m={3} />
              </Hidden>
              <Hidden xsDown={true}>
                <HeaderUsername
                  isSuperAdmin={isSuperAdmin}
                  username={username}
                />
              </Hidden>
            </Toolbar>
          </AppBar>
          {userUnauthorized ? (
            <Unauthorized />
          ) : (
            <WithErrorHandler setUserUnauthorized={setUserUnauthorized}>
              <SurveyModal />
              <nav className={classes.drawer} aria-label="navigation">
                <Hidden smUp={true} implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}
                  >
                    <Menu
                      username={username}
                      isSuperAdmin={isSuperAdmin}
                      confirmedTimeElapsedInCurrentYear={
                        confirmedTimeElapsedInCurrentYear
                      }
                      onClose={handleDrawerToggle}
                    />
                  </Drawer>
                </Hidden>
                <Hidden xsDown={true} implementation="css">
                  <Drawer
                    variant="permanent"
                    classes={{ paper: classes.drawerPaper }}
                    open={true}
                  >
                    <Menu
                      confirmedTimeElapsedInCurrentYear={
                        confirmedTimeElapsedInCurrentYear
                      }
                      onClose={handleDrawerToggle}
                    />
                  </Drawer>
                </Hidden>
              </nav>
              <main className={classes.viewContainer}>
                <div className={classes.drawerHeader} />
                {authentication.isAuthenticated ? (
                  <>
                    <div className={classes.content}>
                      <AutoSizer>
                        {({ width, height }) => {
                          return (
                            <Scrollbar
                              style={{ width, height }}
                              scrollTop={scrollPositionSelected}
                              onScroll={getScrollPositionSelected}
                              noScrollX={true}
                            >
                              <Box className={classes.headerContent}>
                                <Switch>
                                  <Redirect from="/" to="/home" exact={true} />

                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/home`}
                                    component={Home}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module-du-mois/activite/:id`}
                                    component={ActivityLessonOrQuiz}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module-du-mois/:blocId/activite/:id`}
                                    component={ActivityLessonOrQuiz}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module-du-mois/:blocId`}
                                    render={props => (
                                      <Module
                                        {...props}
                                        setStickyTabs={setStickyTabs}
                                        isStickyTabs={stickyTabs}
                                      />
                                    )}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module-du-mois`}
                                    render={props => (
                                      <Module
                                        {...props}
                                        isStickyTabs={stickyTabs}
                                      />
                                    )}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module/:moduleId`}
                                    render={props => (
                                      <Module
                                        {...props}
                                        setStickyTabs={setStickyTabs}
                                        isStickyTabs={stickyTabs}
                                      />
                                    )}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/module/:moduleId/activite/:id`}
                                    component={ActivityLessonOrQuiz}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/modules`}
                                    component={Modules}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/mon-profil`}
                                    component={Profil}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/attestations`}
                                    component={Certificate}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/contact`}
                                    component={Contact}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/faq`}
                                    component={FrequentlyAskedQuestion}
                                  />
                                  <Route
                                    exact={true}
                                    path={`${process.env.PUBLIC_URL}/mentions-legales`}
                                    component={Mention}
                                  />
                                  <Route component={NoMatch} />
                                </Switch>
                              </Box>
                            </Scrollbar>
                          );
                        }}
                      </AutoSizer>
                    </div>
                  </>
                ) : null}
              </main>
            </WithErrorHandler>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(App);
