// React
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// Lib
import moment from "moment";
import { useSnackbar } from "notistack";

import { isEdge, isSafari } from "react-device-detect";
import clsx from "clsx";
// Material
import {
  Box,
  Card,
  CardContent,
  Chip,
  Fab,
  Grid,
  Hidden,
  Typography
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CachedIcon from "@material-ui/icons/Cached";
// Components
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import TimeLabelsCard from "../TimeLabelsCard/TimeLabelsCard";
import TimeProgressCard from "../TimeProgressCard/TimeProgressCard";
// Models
import { Category } from "../../models/category.model";
// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";
// Colors
import { colorCAC } from "../../styles/color";
// Helpers
import {
  getFormattedModuleDescription,
  isPlurial
} from "../../helpers/text-formatter.helper";
// Images
import iconDossierMois from "../../assets/ic-dossier-mois-small.png";
import iconPdf from "../../assets/ic-pdf.svg";
import iconTimeGray from "../../assets/ic-timer-gray.svg";
import iconTimeGreen from "../../assets/ic-timer-green.svg";
// Service
import {
  askModuleAccess,
  moduleCacheRefresh
} from "../../services/navigation.service";
import { getIsSuperAdmin } from "../../services/user.service";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    faSafari: {
      width: "270px !important"
    },
    faEdge: {
      width: "270px !important"
    },
    card: {
      padding: theme.spacing(0.5, 0.5),
      minHeight: "min-content"
    },
    cardLeftBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopRightRadius: "0.5em",
        borderBottomRightRadius: "0.5em"
      },
      [theme.breakpoints.down("sm")]: {
        borderBottomRightRadius: "0.5em",
        borderBottomLeftRadius: "0.5em"
      }
    },
    cardRightBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderBottomLeftRadius: "0.5em"
      },
      [theme.breakpoints.down("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderTopRightRadius: "0.5em"
      }
    },
    cardContent: {
      position: "relative",
      wordWrap: "break-word",
      "&:last-child": {
        paddingBottom: 10
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative"
      }
    },
    cardContentUnvailable: {
      opacity: 0.5,
      filter: "grayscale(100%)"
    },
    button: {
      color: "white",
      fontWeight: "bold",
      textTransform: "none"
    },
    askAccessButton: {
      position: "absolute",
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      bottom: 20
    },
    forceRefreshButton: {
      fontWeight: "bold",
      textTransform: "none",
      width: "100%",
      textAlign: "right",
      marginRight: "15px",
      cursor: "pointer"
    },
    title: {
      textTransform: "uppercase",
      width: "fit-content",
      maxWidth: "100%",
      fontWeight: 600,
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenDark,
      padding: 5,
      [theme.breakpoints.up("xs")]: {
        marginRight: 10
      }
    },
    titleEdge: {
      textTransform: "uppercase",
      width: "auto",
      maxWidth: "37%",
      fontWeight: 600,
      color: "#FFFFFF",
      margin: 2,
      backgroundColor: colorCAC.greenDark,
      padding: 5,
      [theme.breakpoints.up("xs")]: {
        marginRight: 10
      },
      [theme.breakpoints.up("sm")]: {
        display: "table-cell"
      }
    },
    subTitle: {
      textAlign: "center",
      fontSize: 14
    },
    cardClickable: {
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      cursor: "pointer",
      width: "100%"
    },
    cardContentDownload: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed #bbbcbe"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed #bbbcbe"
      },
      padding: 5,
      "&:last-child": {
        paddingBottom: 5
      }
    },
    pdfIcIcon: {
      height: 26,
      margin: "auto"
    },
    titleMonthFolder: {
      fontWeight: 600
    },
    descriptionModule: {
      whiteSpace: "pre-wrap",
      [theme.breakpoints.down("md")]: {
        textAlign: "justify"
      }
    },
    list: {
      "& > ul ": {
        margin: "0px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "90%"
      },
      "& > p ": {
        margin: "0px",
        display: "flex",
        overflowWrap: "break-word",
        flexDirection: "column",
        maxWidth: "90%"
      }
    },
    clockIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start"
    },
    lightGrayText: {
      fontSize: 12,
      lineHeight: 1.67,
      letterSpacing: 0.87,
      color: "rgba(0, 0, 0, 0.5)"
    },
    nbActivities: {
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px solid silver",
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 5,
        "&::before": {
          content: '""',
          display: "block",
          height: 1,
          marginTop: -1,
          backgroundColor: "silver",
          position: "absolute",
          width: 30
        }
      }
    },
    chipType: {
      color: "white"
    }
  });
});

/**
 * ModuleCardProps props
 */
interface IModuleCardProps {
  moduleMonth: Category;
}

/**
 * ModuleCard component
 */
const ModuleCard = (props: RouteComponentProps & IModuleCardProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** Classes  */
  const classes = useStyles();
  /** Props */
  const { history, moduleMonth } = props;
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();
  /** asked */
  const [asked, setAsked] = useState(false);
  /** button */
  const [openModal, setOpenModal] = useState(false);
  /** super admin */
  const [isSuperAdmin] = useState(getIsSuperAdmin());
  /** Theme */
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));

  /**
   * Refresh cache module
   */
  const refreshCache = async (e: any, force?: boolean) => {
    e.stopPropagation();

    enqueueSnackbar(
      `Cache du module ${moduleMonth.name} en cours rafraichissement.`,
      {
        variant: "info"
      }
    );

    try {
      const result = await moduleCacheRefresh(
        moduleMonth.id,
        fetchWithCredentials,
        force
      );
      enqueueSnackbar(
        result.data.count === 0
          ? `Cache du module ${moduleMonth.name} déjà à jour.`
          : `Cache du module ${moduleMonth.name} et ${result.data.count} activité(s) rafraichi avec succès.`,
        {
          variant: "success",
          action: force ? null : (
            <div
              className={classes.forceRefreshButton}
              onClick={e => refreshCache(e, true)}
            >
              Forcer le rafraichissement total
            </div>
          )
        }
      );
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error"
      });
    }
  };

  /**
   * get pdf url
   * @param certificateId
   */
  const getPdfUrl = (): void => {
    if (moduleMonth.file) {
      window.open(moduleMonth.file, "_blank");
    }
  };

  /**
   * Open module
   */
  const openModule = (): void => {
    if (moduleMonth.available) {
      history.push("/module/" + moduleMonth.id);
    }
  };

  /**
   * modal close
   */
  const modalClose = (): void => {
    setOpenModal(false);
  };

  /**
   * modal confirm
   */
  const modalConfirm = async () => {
    setAsked(true);

    const result = await askModuleAccess(moduleMonth.id, fetchWithCredentials);
    if (result.data.code === 201 || result.data.code === 200) {
      enqueueSnackbar(result.data.message, {
        variant: "success"
      });
      setOpenModal(false);
      return;
    }
    enqueueSnackbar("Erreur lors de la prise en compte de votre demande", {
      variant: "error"
    });
  };

  return (
    <>
      <ConfirmModal
        handleClose={modalClose}
        handleConfirm={modalConfirm}
        open={openModal}
        confirmed={asked}
        title={`Demande d'accès`}
        content={`Confirmez-vous la demande d'accès au module de <b>${moduleMonth.name}</b> ?
        <p>Le service client vous contactera pour valider votre demande.</p>`}
      />
      <Grid container={true} item={true} xs={true} className={classes.card}>
        <Grid item={true} xs={12} md={moduleMonth.file ? 9 : 12}>
          <Card
            className={`${classes.cardLeftBorder} ${
              moduleMonth.available ? classes.cardClickable : ""
            }`}
            onClick={() => openModule()}
          >
            <CardContent className={classes.cardContent}>
              <Hidden smDown={true}>
                <Grid
                  container={true}
                  spacing={3}
                  alignItems="center"
                  justify="center"
                  className={`${
                    !moduleMonth.available ? classes.cardContentUnvailable : ""
                  }`}
                >
                  <Grid
                    item={true}
                    container={true}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    md={3}
                    lg={2}
                  >
                    <Grid item={true}>
                      <TimeProgressCard
                        isExpired={moduleMonth.expired}
                        progress={moduleMonth.time.progress}
                      />
                    </Grid>
                    {moduleMonth.approvalType === "H" ? (
                      <Grid item={true}>
                        <Chip
                          size="small"
                          label="Homologué"
                          className={classes.chipType}
                          style={{ backgroundColor: colorCAC.orange }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item={true} md={9} lg={10}>
                    <Grid item={true} container={true} alignItems="center">
                      <Grid
                        item={true}
                        xs={true}
                        style={isEdge ? { marginBottom: 8 } : undefined}
                      >
                        <Box
                          marginBottom={1}
                          marginTop={1}
                          fontSize="body1.fontSize"
                          className={clsx({
                            [classes.title]: !isEdge,
                            [classes.titleEdge]: isEdge
                          })}
                        >
                          <p style={{ marginTop: 0, marginBottom: 0 }}>
                            Module {moduleMonth.name}
                          </p>
                        </Box>
                      </Grid>
                      {moduleMonth.endDate ? (
                        <Grid item={true}>
                          <Typography
                            component="p"
                            gutterBottom={false}
                            className={classes.lightGrayText}
                          >
                            Fin de validité :{" "}
                            {moduleMonth.expired
                              ? "archivé"
                              : moment(moduleMonth.endDate).format("L")}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid item={true} container={true} spacing={2}>
                      <Grid container={true} item={true}>
                        {moduleMonth.titleMonthFolder != "" && (
                          <Typography
                            component="p"
                            gutterBottom={false}
                            className={classes.titleMonthFolder}
                          >
                            <img
                              src={iconDossierMois}
                              alt="dossier-du-mois"
                              height={20}
                            />
                            &nbsp; Dossier du mois :{" "}
                            {moduleMonth.titleMonthFolder}
                          </Typography>
                        )}
                      </Grid>
                      {moduleMonth.available && moduleMonth.description ? (
                        <Grid item={true} xs={12}>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            gutterBottom={true}
                            className={classes.descriptionModule}
                          >
                            {
                              <div
                                className={classes.list}
                                dangerouslySetInnerHTML={{
                                  __html: getFormattedModuleDescription(
                                    moduleMonth.description
                                  )
                                }}
                              />
                            }
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid
                        container={true}
                        item={true}
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item={true} xs={1} className={classes.clockIcon}>
                          {moduleMonth.time.estimated ===
                          moduleMonth.time.confirmed ? (
                            <img src={iconTimeGreen} alt="timer vert" />
                          ) : (
                            <img src={iconTimeGray} alt="timer gris" />
                          )}
                        </Grid>
                        <Grid item={true} xs={7}>
                          <TimeLabelsCard
                            confirmedTime={moduleMonth.time.confirmed}
                            estimatedTime={moduleMonth.time.estimated}
                            approval={
                              moduleMonth.approvalType === "H" ? true : false
                            }
                            entity={"module"}
                          />
                        </Grid>
                        <Grid item={true} xs={1} />
                        <Grid item={true} xs={3}>
                          <Typography
                            component="p"
                            gutterBottom={false}
                            className={`${classes.lightGrayText} ${classes.nbActivities}`}
                          >
                            {moduleMonth.nbActivities}{" "}
                            {isPlurial(moduleMonth.nbActivities)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>

              <Hidden mdUp={true}>
                <Grid
                  container={true}
                  spacing={2}
                  alignItems="center"
                  justify="center"
                  className={`${
                    !moduleMonth.available ? classes.cardContentUnvailable : ""
                  }`}
                >
                  <Grid
                    item={true}
                    container={true}
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item={true}>
                      <TimeProgressCard
                        isExpired={moduleMonth.expired}
                        progress={moduleMonth.time.progress}
                      />
                    </Grid>
                    <Grid item={true} container={true} xs={true}>
                      <Grid item={true}>
                        <Box
                          marginBottom={1}
                          marginTop={1}
                          fontSize="body1.fontSize"
                          className={classes.title}
                        >
                          Module {moduleMonth.name}
                        </Box>
                      </Grid>
                      {moduleMonth.endDate ? (
                        <Grid item={true}>
                          <Typography
                            component="p"
                            gutterBottom={false}
                            className={classes.lightGrayText}
                          >
                            Fin de validité :{" "}
                            {moment(moduleMonth.endDate).format("L")}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    item={true}
                    container={true}
                    xs={true}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid container={true} item={true}>
                      {moduleMonth.titleMonthFolder != "" && (
                        <Typography
                          component="p"
                          gutterBottom={false}
                          className={classes.titleMonthFolder}
                        >
                          <img
                            src={iconDossierMois}
                            alt="dossier-du-mois"
                            height={20}
                          />
                          &nbsp; Dossier du mois :{" "}
                          {moduleMonth.titleMonthFolder}
                        </Typography>
                      )}
                    </Grid>
                    {moduleMonth.available && moduleMonth.description ? (
                      <Grid container={true} item={true}>
                        <Typography
                          component="div"
                          gutterBottom={false}
                          className={classes.descriptionModule}
                        >
                          <div
                            className={classes.list}
                            dangerouslySetInnerHTML={{
                              __html: getFormattedModuleDescription(
                                moduleMonth.description
                              )
                            }}
                          />
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid
                    item={true}
                    container={true}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item={true} xs={2} className={classes.clockIcon}>
                      {moduleMonth.time.estimated ===
                      moduleMonth.time.confirmed ? (
                        <img src={iconTimeGreen} alt="timer vert" />
                      ) : (
                        <img src={iconTimeGray} alt="timer gris" />
                      )}
                    </Grid>
                    <Grid item={true} container={true} xs={10}>
                      <Grid item={true}>
                        <TimeLabelsCard
                          confirmedTime={moduleMonth.time.confirmed}
                          estimatedTime={moduleMonth.time.estimated}
                          approval={
                            moduleMonth.approvalType === "H" ? true : false
                          }
                          entity={"module"}
                        />
                      </Grid>
                      <Grid item={true}>
                        <Typography
                          component="p"
                          gutterBottom={false}
                          className={`${classes.lightGrayText} ${classes.nbActivities}`}
                        >
                          {moduleMonth.nbActivities}{" "}
                          {isPlurial(moduleMonth.nbActivities)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>

              <Grid
                item={true}
                container={true}
                alignItems="center"
                justify="center"
              >
                <Grid item={true} style={{ margin: 15 }}>
                  {isSuperAdmin ? (
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      className={classes.button}
                      onClick={refreshCache}
                    >
                      <CachedIcon />
                      &nbsp; Vider le cache module
                    </Fab>
                  ) : null}

                  {!moduleMonth.available && !isSuperAdmin ? (
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      onClick={() => setOpenModal(true)}
                      className={`
                        ${classes.button}  
                        ${clsx([
                          {
                            [classes.faSafari]: isSafari,
                            [classes.faEdge]: isEdge
                          }
                        ])}`}
                    >
                      Demander l'accès au module
                    </Fab>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {moduleMonth.file ? (
          <Grid item={true} xs={12} md={3} container={true}>
            <Card
              className={`${classes.cardClickable} ${classes.cardRightBorder}`}
              onClick={() => getPdfUrl()}
            >
              <Grid
                container={true}
                justify="center"
                alignItems="center"
                className={classes.cardContentDownload}
                direction={isUpMd ? "column" : "row"}
              >
                <Grid item={true}>
                  <ListItemIcon>
                    <img
                      src={iconPdf}
                      alt="icon-attestation"
                      className={classes.pdfIcIcon}
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item={true}>
                  <Typography
                    className={classes.subTitle}
                    color="textPrimary"
                    gutterBottom={false}
                  >
                    Télécharger le sommaire
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default withRouter(React.memo(ModuleCard));
