// React
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// Material ui
import { Box, Fab, Grid } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Libs
import Helmet from "react-helmet";
// Models
import { Category } from "../../models/category.model";
// Services
import { getModules } from "../../services/navigation.service";
// Components
import ModuleCard from "../../features/ModuleCard/ModuleCard";
import NoResult from "../../features/NoResult/NoResult";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
// Color
import { colorCAC } from "../../styles/color";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 16,
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      },
      ["@supports (-webkit-touch-callout: none)"]: {
        marginBottom: 149
      }
    },
    header: {
      position: "sticky",
      background: colorCAC.grayLight,
      width: "calc(100% + 20px)",
      zIndex: 3,
      top: 0,
      paddingTop: 10,
      marginTop: -10,
      paddingLeft: 10,
      marginLeft: -10,
      marginRight: 10
    },
    title: {
      color: colorCAC.orange,
      fontWeight: "bold"
    },
    button: {
      color: "white",
      fontWeight: "bold",
      marginTop: theme.spacing(5),
      textTransform: "none"
    }
  })
);

/**
 * Modules Component
 */
const Modules = (props: RouteComponentProps) => {
  /**
   * Keycloak
   */
  const { fetchWithCredentials } = useUserAuthentication();

  const { history } = props;

  /** Classes  */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  /** loading state */
  const [loading, setLoading] = useState<boolean>(true);
  /** Modules list state */
  const [moduleList, setModuleList] = useState<any>();

  /** Use effect */
  useEffect(() => {
    const fetchData = async () => {
      const result = await getModules(fetchWithCredentials);
      setModuleList(result.data);
      setLoading(false);
    };

    let isSubscribed = true;
    if (isSubscribed) {
      fetchData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  /**
   * Redirect to home page
   */
  const redirectToHome = (): void => {
    history.push({
      pathname: "/home"
    });
  };

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Tous mes modules - RF e-Learning CAC</title>
      </Helmet>
      {!loading ? (
        <React.Fragment>
          <Grid
            container={true}
            spacing={3}
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.container}
          >
            {moduleList && moduleList.length > 0 ? (
              <Grid item={true} xs={true}>
                <React.Fragment>
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="center"
                    justify={isUpMd ? "flex-start" : "center"}
                    className={classes.header}
                  >
                    <Grid item={true}>
                      <Box
                        marginBottom={1}
                        fontSize="h6.fontSize"
                        className={classes.title}
                      >
                        Tous mes modules
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    {moduleList.map((moduleMonth: Category) => (
                      <ModuleCard
                        key={moduleMonth.id}
                        moduleMonth={moduleMonth}
                      />
                    ))}
                  </Grid>
                </React.Fragment>
              </Grid>
            ) : (
              <React.Fragment>
                <NoResult
                  isCertificate={true}
                  titleCustom=""
                  subTitleCustom="Vous ne disposez pas encore de modules."
                />
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={() => redirectToHome()}
                  className={classes.button}
                >
                  Retour à la page d'accueil
                </Fab>
              </React.Fragment>
            )}
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            container={true}
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.container}
          >
            <Grid item={true} style={{ marginRight: isUpMd ? "auto" : 0 }}>
              <SkeletonLoader width={200} height={30} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
            <Grid item={true}>
              <SkeletonLoader width={1280} height={210} />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(React.memo(Modules));
