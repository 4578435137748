/**
 * Constante
 */
export const CONST = {
  CURRENT_YEAR: new Date().getFullYear(),
  DOMAIN: "r7-rfactuaweb2.kreactive.eu", // prod "rf-elearning-cac.com" staging "test-rf-actua.kreactive.eu" preprod "r7-rfactuaweb2.kreactive.eu"
  PAGE_PRODUCTLIST: "https://portail.rf-elearning-cac.com/",
  PAGE_RF_COMPTABLE: "https://rfcomptable.grouperf.com",
  KEYCLOAK: {
    URL: process.env.REACT_APP_KEYCLOAK_URL || "",
    REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
    CLIENTID: process.env.REACT_APP_KEYCLOAK_CLIENTID || ""
  },
  RF_LEARNING_URL: process.env.REACT_APP_URL_RF_LEARNING,
  API: {
    MODULES:
      "/modules?query={id,name,file,available,expired,time,approvalType,endDate,titleMonthFolder,description,estimatedTime,confirmedTime,nbActivities}&opt=year",
    MODULES_ACCESS: "/modules/:moduleId/access",
    MODULE_CACHE_REFRESH: "/cache/module/:moduleId",
    LAST_MODULE:
      "/modules/last?query={id,nbActivities,name,description,startDate,endDate,time{estimated},children{id,name,nbActivities,time},titleMonthFolder,descriptionMonthFolder,expertOpinion}",
    MONTH_MODULE:
      "/modules/:moduleId?query={id,name,expired,quizzesStarted,children{id,name,nbActivities,time,children}}&from=site",
    BILAN_BLOC: "/blocs/:blocId?query={children{id,name,time}}",
    TIME_YEAR: "/timeElapsedPerActivty/year/:year",
    CATEGORY_LIST: {
      BASE: "/modules",
      BASE_ELAPSEDTIME: "/modules?embed=elapsedTime"
    },
    CERTIFICATE_LIST:
      "/certificates?query={id,startDate,endingFormationDate,name,time,approvalNumber}",
    CERTIFICATE: "/attestation",
    CERTIFICATE_PDF: "/certificates/pdf",
    CERTIFICATE_FILES: "/certificates/zip",
    ACTIVITY: "/activities/:activityId",
    TIME: "/activities/:activityId/timesElapsed",
    CONTACT: "/contact/send",
    SUBJECT: "/contact/asks",
    USER: "/users/me",
    NEWSLETTER: "/users/newsletter",
    SURVEY: "/users/survey",
    QUIZ_VALIDED_QUESTION: "/quizzes/:activityId/questions/:questionId",
    QUIZ_RESET: "/quizzes/:activityId/reset"
  },
  ROUTES: {
    HOME: "/home",
    MONTH_MODULE: "/module-du-mois",
    MODULES: "/modules",
    DASHBOARD: "/mon-profil",
    CERTIFICATE: "/attestations",
    CONTACT: "/contact",
    FAQ: "/faq",
    MENTIONS: "/mentions-legales"
  },
  GA_ID: process.env.REACT_APP_GA_ID || "UA-110057889-X",
  SURVEY_ID: process.env.REACT_APP_SURVEY_ID || "Wsv3c4ai"
};

export const ROUTES = [
  { ID: 0, PATH: CONST.ROUTES.HOME },
  { ID: 1, PATH: CONST.ROUTES.MONTH_MODULE },
  { ID: 2, PATH: CONST.ROUTES.MODULES },
  { ID: 3, PATH: CONST.ROUTES.DASHBOARD },
  { ID: 4, PATH: CONST.ROUTES.CERTIFICATE },
  { ID: 5, PATH: CONST.ROUTES.CONTACT },
  { ID: 6, PATH: CONST.ROUTES.FAQ },
  { ID: 7, PATH: CONST.ROUTES.MENTIONS }
];
