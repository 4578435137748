import { Reducer } from "redux";
import { activityLessonOrQuizActions } from "./action";
import { CAN_PLAY_TIMER, SET_START_DATE } from "./constants";

export interface IActivityLessonOrQuiz {
  canPlayTimer: boolean;
  startDate: number;
}

const initialState: IActivityLessonOrQuiz = {
  canPlayTimer: false,
  startDate: 0
};

const reducer: Reducer<any, activityLessonOrQuizActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CAN_PLAY_TIMER:
      return {
        ...state,
        canPlayTimer: action.payload
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      };
    default:
      return state;
  }
};

export { reducer as activityLessonOrQuizReducer };
