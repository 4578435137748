// React
import React from "react";

// Material
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import withWidth, { isWidthUp, WithWidth } from "@material-ui/core/withWidth";

// Models
import { Bloc } from "../../models/bloc.model";
import { Category } from "../../models/category.model";

// Helper
import { getBlocIcon, getBlocWave } from "../../helpers/bloc.helper";

// Components
import LastModuleBlocCard from "../LastModuleBlocCard/LastModuleBlocCard";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blocs: {
      [theme.breakpoints.up("md")]: {
        alignItems: "center"
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    }
  })
);

/**
 * LastModuleBlocs props
 */
interface ILastModuleBlocsProps {
  category: Category;
}

/**
 * LastModuleBlocs component
 */
const LastModuleBlocs = (props: WithWidth & ILastModuleBlocsProps) => {
  /** Classes  */
  const classes = useStyles();
  const { category, width } = props;

  return (
    <React.Fragment>
      <Grid item={true} xs={true}>
        <Grid
          container={true}
          className={classes.blocs}
          justify="space-between"
          spacing={isWidthUp("md", width) ? 2 : 0}
        >
          {category.children
            ? category.children.map((bloc: Bloc) => (
                <Grid item={true} xs={12} md={4} key={bloc.id}>
                  <LastModuleBlocCard
                    id={bloc.id}
                    title={bloc.name}
                    nbActivities={bloc.nbActivities}
                    percentage={bloc.time.progress}
                    icon={getBlocIcon(bloc.name)}
                    wave={getBlocWave(bloc.name)}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(withWidth()(LastModuleBlocs));
