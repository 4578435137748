// React
import React from "react";

// Material
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Models
import { Category } from "../../models/category.model";

// Styles
import { colorCAC } from "../../styles/color";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: colorCAC.grayCard,
      padding: theme.spacing(0.5, 0.5),
      [theme.breakpoints.up("md")]: {
        borderLeft: "4px outset",
        borderLeftColor: colorCAC.purple
      },
      [theme.breakpoints.down("sm")]: {
        borderTop: "4px outset",
        borderTopColor: colorCAC.purple
      }
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.purple,
      paddingLeft: 3,
      paddingRight: 3
    }
  })
);

/**
 * LastModuleInfoCard props
 */
interface ILastModuleInfoCardProps {
  category: Category;
}

/**
 * LastModuleInfoCard component
 */
const LastModuleInfoCard = ({ category }: ILastModuleInfoCardProps) => {
  /** Classes  */
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid container={true}>
            <Grid item={true}>
              <Box
                marginBottom={1}
                marginTop={1}
                fontWeight="600"
                color="textPrimary"
                fontSize="h6.fontSize"
                className={classes.title}
              >
                DOSSIER DU MOIS
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              <Box
                marginBottom={1}
                marginTop={1}
                fontWeight="600"
                color="textPrimary"
                fontSize="h6.fontSize"
              >
                {category.titleMonthFolder}
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                gutterBottom={true}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.descriptionMonthFolder
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(LastModuleInfoCard);
