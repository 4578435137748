// React
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// Libs
import Helmet from "react-helmet";
// Services
import { getLastModule } from "../../services/navigation.service";
// Components
import LastModuleBlocs from "../../features/LastModuleBlocs/LastModuleBlocs";
import LastModuleCard from "../../features/LastModuleCard/LastModuleCard";
import LastModuleInfoCard from "../../features/LastModuleInfoCard/LastModuleInfoCard";
import LastModuleInfoExpertCard from "../../features/LastModuleInfoExpertCard/LastModuleInfoExpertCard";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
// Models
import { Category } from "../../models/category.model";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
// Assets
//import bandeau from "../../assets/Année_2024@2x.png";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 40,
        paddingRight: 40
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      ["@supports (-webkit-touch-callout: none)"]: {
        marginBottom: 149
      }
    },
    bandeau: {
      width: "100%",
      marginBottom: 12
    }
  })
);

/**
 * Home Component
 */
const Home = () => {
  /** Classes  */
  const classes = useStyles();

  /** loading state */
  const [loading, setLoading] = useState(true);
  /** Last module state */
  const [lastModule, setModule] = useState<Category>();

  const { fetchWithCredentials } = useUserAuthentication();

  /** Use effect */
  useEffect(() => {
    const fetchData = async () => {
      const result = await getLastModule(fetchWithCredentials);
      setModule(result.data);
      setLoading(false);
    };

    let isSubscribed = true;
    if (isSubscribed) {
      fetchData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Accueil - RF e-Learning CAC</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        {/*         <img
          src={bandeau}
          alt="Nous vous souhaitons bonne fete"
          className={classes.bandeau}
        /> */}
      </Grid>

      {!loading && lastModule ? (
        <React.Fragment>
          <Grid
            container={true}
            direction="column"
            spacing={2}
            className={classes.container}
          >
            <Grid item={true}>
              <LastModuleCard category={lastModule} />
              <Hidden mdUp={true}>
                <LastModuleBlocs category={lastModule} />
              </Hidden>
            </Grid>
            {lastModule.descriptionMonthFolder != "" &&
              lastModule.titleMonthFolder != "" && (
                <Grid item={true}>
                  <LastModuleInfoCard category={lastModule} />
                </Grid>
              )}
            {lastModule.expertOpinion && (
              <Grid item={true}>
                <LastModuleInfoExpertCard category={lastModule} />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            container={true}
            direction="column"
            spacing={2}
            className={classes.container}
          >
            <SkeletonLoader height={350} />
            <SkeletonLoader height={350} />
            <SkeletonLoader height={350} />
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
