/** Constante */
import { CONST } from "../config/constant";

/**
 * Post time activity
 * @param startDate
 * @param endDate
 * @param activityId
 */
export const postTimeActivity = async (
  startDate: string,
  endDate: string,
  activityId: string,
  fetchWithCredentials: typeof fetch
) => {
  /** URL Time */
  const URL_TIME = `${process.env.REACT_APP_API_URL}${CONST.API.TIME}`;
  /** params */
  const params = {
    end: endDate,
    start: startDate
  };

  const result = await fetchWithCredentials(
    URL_TIME.replace(/:activityId/, activityId),
    { method: "POST", body: JSON.stringify(params) }
  );

  return await result.json();
};
