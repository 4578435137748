/** Constant */
import { CONST } from "../config/constant";

/** Models */
import { User } from "../models/user.model";

/** Store */
import { store } from "../store/createStore";

/**
 * Get username
 */
export const getUsername = (keycloak: any): string => {
  // Waiting keycloak update
  return keycloak!.tokenParsed.name || "";
};

/**
 * Is super admin
 */
export const getIsSuperAdmin = (): boolean => {
  const user: User | null = store.getState().user;
  return user ? user.roles.includes("super_admin") : false;
};

/**
 * Disconnect user
 */
export const disconnectUser = (keycloak: any) => {
  keycloak && keycloak.logout();
};

/**
 * Get user's informations
 */
export const getUser = async (fetchCredentials: typeof fetch) => {
  /** URL user information */
  const URL_USER_INFORMATION: string = `${process.env.REACT_APP_API_URL}${CONST.API.USER}`;
  return await fetchCredentials(URL_USER_INFORMATION);
};

interface INewsletter {
  alertType: string;
  subscribed: boolean | null;
  updatedAt: string;
}

/**
 * Get user's newsletter
 */
export const getUserNewsletter = async (
  fetchCredentials: typeof fetch
): Promise<boolean> => {
  const URL_USER_NEWSLETTER: string = `${process.env.REACT_APP_API_URL}${CONST.API.NEWSLETTER}`;
  const result = await fetchCredentials(URL_USER_NEWSLETTER);
  return await result.json().then(res => res.data && res.data.subscribed);
};

/**
 * Patch user newsletter
 * alertType : number
 * subscribed : number
 */
export const changeUserNewsletter = async (
  fetchCredentials: typeof fetch,
  choice: boolean
) => {
  const param = {
    choice
  };
  const URL_USER_NEWSLETTER: string = `${process.env.REACT_APP_API_URL}${CONST.API.NEWSLETTER}`;
  const result = await fetchCredentials(URL_USER_NEWSLETTER, {
    method: "PATCH",
    body: JSON.stringify(param)
  });
  return await result.json();
};

/**
 * Get user's newsletter
 */
export const getUserSurvey = async (
  fetchCredentials: typeof fetch
): Promise<INewsletter> => {
  const URL_USER_SURVEY: string = `${process.env.REACT_APP_API_URL}${CONST.API.SURVEY}`;
  const result = await fetchCredentials(URL_USER_SURVEY);
  return await result.json().then(res => res.data);
};

/**
 * Patch user newsletter
 * choice : boolean
 */
export const changeUserSurvey = async (
  fetchCredentials: typeof fetch,
  choice: boolean
) => {
  const param = {
    choice
  };
  const URL_USER_SURVEY: string = `${process.env.REACT_APP_API_URL}${CONST.API.SURVEY}`;
  const result = await fetchCredentials(URL_USER_SURVEY, {
    method: "PATCH",
    body: JSON.stringify(param)
  });
  return await result.json();
};
