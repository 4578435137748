// React
import { CSSProperties } from "react";

// Images
import waveEnvLegalPro from "../assets/green-wave.svg";
import iconDossierMois from "../assets/ic-dossier-mois.svg";
import iconEnvLegalPro from "../assets/ic-env-legal-pro.svg";
import iconPratiqueComptable from "../assets/ic-pratique-comptable.svg";
import waveDossierMois from "../assets/purple-wave.svg";
import wavePratiqueComptable from "../assets/yellow-wave.svg";

/** Modules name's first word */
const pratiqueModule = "pratique";
const environnementModule = "environnement";
const dossierModule = "dossier";

/**
 * Get bloc's icon
 * @param blocName
 */
export const getBlocIcon = (blocName: string): string => {
  const moduleFirstWord = blocName.replace(/ .*/, "").toLowerCase();
  switch (moduleFirstWord) {
    case pratiqueModule:
      return iconPratiqueComptable;
    case environnementModule:
      return iconEnvLegalPro;
    case dossierModule:
      return iconDossierMois;
    default:
      return "";
  }
};

/**
 * Get bloc's wave
 * @param blocName
 */
export const getBlocWave = (blocName: string): string => {
  const moduleFirstWord = blocName.replace(/ .*/, "").toLowerCase();
  switch (moduleFirstWord) {
    case pratiqueModule:
      return wavePratiqueComptable;
    case environnementModule:
      return waveEnvLegalPro;
    case dossierModule:
      return waveDossierMois;
    default:
      return "";
  }
};

export const getIndexBlocByName = (blocName: string): number => {
  return [
    "PRATIQUE COMPTABLE",
    "ENVIRONNEMENT LÉGAL ET PROFESSIONNEL",
    "DOSSIER DU MOIS"
  ].indexOf(blocName);
};

/** Get indicator's background color */
export const getIndicatorColor = (blocName: string): CSSProperties => {
  return {
    backgroundColor:
      ["#f9cc00", "#006e49", "#82368c"][getIndexBlocByName(blocName)] || "#000"
  };
};
