// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

// Material
import { Box, Card, CardContent, Chip, Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Colors
import { colorCAC } from "../../styles/color";

// Images
import iconTimeGray from "../../assets/ic-timer-gray.svg";
import iconTimeGreen from "../../assets/ic-timer-green.svg";

// Models
import { Activity } from "../../models/activity.model";

// Helpers
import { getBackgroundTypeActivity } from "../../helpers/color-translator.helper";
import { formatterTypeActivity } from "../../helpers/text-formatter.helper";
import clsx from "clsx";

// Components
import TimeLabelsCard from "../TimeLabelsCard/TimeLabelsCard";
import TimeProgressCard from "../TimeProgressCard/TimeProgressCard";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: "auto",
      [theme.breakpoints.up("md")]: {
        maxWidth: "95%"
      }
    },
    card: {
      padding: theme.spacing(0.5, 0.5),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      [theme.breakpoints.down("sm")]: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }
    },
    cardContent: {
      paddingBottom: 0
    },
    chipType: {
      color: "white",
      marginBottom: theme.spacing(0.5),
      fontWeight: 600
    },
    title: {
      fontSize: 16,
      justifyContent: "center",
      letterSpacing: 0.5,
      color: colorCAC.black
    },
    clockIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start"
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  })
);

const HeaderCard = (props: any) => {
  const classes = useStyles();
  const { type, name } = props;

  return (
    <Grid item={true} md={true}>
      <Chip
        size="small"
        label={formatterTypeActivity(type)}
        className={classes.chipType}
        style={{ backgroundColor: getBackgroundTypeActivity(type) }}
      />
      <Box
        marginBottom={1}
        marginTop={1}
        color="textPrimary"
        fontSize="body1.fontSize"
        className={classes.title}
      >
        {name}
      </Box>
    </Grid>
  );
};

/**
 * Card activity props
 */
interface ICardActivityProps {
  activity: Activity;
  approval?: boolean;
  expired?: boolean;
  locked?: boolean;
  handleCardSelected?: () => void | undefined;
}

/**
 * Card activity
 */
const CardActivity = (props: RouteComponentProps & ICardActivityProps) => {
  const {
    match,
    history,
    activity,
    approval,
    expired,
    locked,
    handleCardSelected
  } = props;

  /** Classes */
  const classes = useStyles();
  /** progress folder */
  const [progress, setProgress] = React.useState(0);
  /** Open activity */
  const openActivity = (): void => {
    if (handleCardSelected) {
      handleCardSelected();
    }
    history.push({
      pathname: match.url + "/activite/" + activity.uniqueId,
      state: { expired }
    });
  };
  /** Use effect progress */
  React.useEffect(() => {
    setProgress(
      (activity.quiz ? activity.questionProgress : activity.time.progress) || 0
    );
  }, [activity.time.estimated]);

  const timeLabelsCard = (
    <TimeLabelsCard
      confirmedTime={activity.time.confirmed}
      estimatedTime={activity.time.estimated}
      approval={approval}
      entity={"activity"}
      isQuiz={activity.quiz}
      validatedQuestionCount={activity.validatedQuestionCount}
      questionCount={activity.questionCount}
    />
  );

  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.locked]: locked
      })}
    >
      <Grid
        id={activity.uniqueId}
        container={true}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item={true} xs={true}>
          <Card className={classes.card} onClick={() => openActivity()}>
            <CardContent className={classes.cardContent}>
              <Hidden smDown={true}>
                <Grid container={true} alignItems="center" justify="center">
                  <Grid item={true} md={2}>
                    <TimeProgressCard isExpired={expired} progress={progress} />
                  </Grid>
                  <Grid item={true} md={10}>
                    <Grid item={true}>
                      <HeaderCard type={activity.type} name={activity.name} />
                      <Grid item={true} md={true} style={{ display: "flex" }}>
                        <Grid item={true} md={1} className={classes.clockIcon}>
                          {activity.time.estimated ===
                          activity.time.confirmed ? (
                            <img src={iconTimeGreen} alt="timer vert" />
                          ) : (
                            <img src={iconTimeGray} alt="timer gris" />
                          )}
                        </Grid>
                        <Grid item={true} md={6}>
                          {timeLabelsCard}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>

              <Hidden mdUp={true}>
                <Grid container={true}>
                  <Grid
                    container={true}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item={true} xs={3}>
                      <TimeProgressCard
                        isExpired={expired}
                        progress={progress}
                      />
                    </Grid>
                    <Grid item={true} xs={8}>
                      <Grid
                        container={true}
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <HeaderCard type={activity.type} name={activity.name} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true} md={true} xs={12}>
                    <Grid container={true} item={true}>
                      <Grid item={true} xs={2} className={classes.clockIcon}>
                        {activity.time.estimated === activity.time.confirmed ? (
                          <img src={iconTimeGreen} alt="timer vert" />
                        ) : (
                          <img src={iconTimeGray} alt="timer gris" />
                        )}
                      </Grid>
                      <Grid item={true} xs={10}>
                        {timeLabelsCard}
                      </Grid>
                      <Grid item={true} xs={3} />
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(React.memo(CardActivity));
