// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

// Redux
import { setActiveBloc } from "../../store/actions";

// Material
import {
  Box,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// Components
import { BorderLinearProgress } from "../BorderLinearProgress/BorderLinearProgress";

// Colors
import { colorCAC } from "../../styles/color";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    time: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    cardBloc: {
      transition: "0.75s",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      [theme.breakpoints.up("md")]: {
        // width: 208,
        width: "100%",
        height: 180
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%"
      }
    },
    cardcontent: {
      position: "relative",
      height: "100%",
      padding: 10,
      "&:last-child": {
        paddingBottom: 0
      }
    },
    circleCategory: {
      backgroundColor: colorCAC.grayLight
    },
    title: {
      [theme.breakpoints.up("md")]: {
        minHeight: 36
      }
    },
    icon: {
      width: "auto",
      height: "auto"
    },
    wave: {
      position: "absolute",
      width: "auto",
      height: "auto",
      display: "flex",
      right: 0,
      bottom: 0
    }
  })
);
/**
 * LastModuleBlocCard props
 */
interface ILastModuleBlocCardProps {
  id: number;
  title: string;
  nbActivities: number;
  percentage: number;
  icon: string;
  wave: string;
}

/**
 * LastModuleBlocCard component
 */
const LastModuleBlocCard = (
  props: RouteComponentProps & ILastModuleBlocCardProps
) => {
  /** Props */
  const { id, history, title, nbActivities, percentage, icon, wave } = props;

  /** Classes  */
  const classes = useStyles();

  /** Open month module bloc */
  const openMonthModuleBloc = (): void => {
    setActiveBloc(id);
    history.push("/module-du-mois");
  };

  return (
    <Card className={classes.cardBloc}>
      <CardContent
        className={classes.cardcontent}
        onClick={() => openMonthModuleBloc()}
      >
        <Grid
          container={true}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Icon className={classes.icon}>
            <img src={icon} alt="icon" />
          </Icon>
          <IconButton
            color="default"
            size="small"
            className={classes.circleCategory}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Box
          fontWeight="600"
          color="textPrimary"
          fontSize="subtitle1.fontSize"
          className={classes.title}
        >
          {title}
        </Box>
        <Typography variant="body1" component="h2">
          {nbActivities} activités
        </Typography>
        <Grid
          container={true}
          direction="row"
          justify="flex-start"
          alignItems="baseline"
        >
          <BorderLinearProgress
            variant="determinate"
            value={percentage}
            color="primary"
          />
          <Typography
            variant="subtitle2"
            noWrap={true}
            className={classes.time}
          >
            {percentage}%
          </Typography>
        </Grid>
        <Icon className={classes.wave}>
          <img src={wave} alt="wave" />
        </Icon>
      </CardContent>
    </Card>
  );
};

export default withRouter(React.memo(LastModuleBlocCard));
