import { colorCAC } from "../styles/color";

/**
 * Get background of type activity
 * @param typeActivity
 */
export const getBackgroundTypeActivity = (typeActivity: string): string => {
  let color = "white";
  switch (typeActivity) {
    case "ficheInfo":
      color = colorCAC.orange;
      break;
    case "video":
      color = colorCAC.greenDark;
      break;
    case "annonce":
      color = colorCAC.black; // TODO: set correct color
      break;
    case "quizThematique":
      color = colorCAC.greenDark;
      break;
    case "quizFinal":
      color = colorCAC.rose;
      break;
    case "categorisation":
      color = colorCAC.greenLight;
      break;
    case "analyse":
      color = colorCAC.purple;
      break;
    case "casPratique":
      color = colorCAC.purple;
      break;
  }
  return color;
};

/**
 * Get bilan color
 * @param success
 */
export const handleBilanColor = (success: number): colorCAC => {
  return success > 75
    ? colorCAC.greenRF
    : success >= 50 && success <= 75
    ? colorCAC.orange
    : colorCAC.redQuiz;
};
