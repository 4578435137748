import { Action, ActionTypes } from "../../actions";

export const confirmedTimeElapsedInCurrentYearReducer = (
  state: number = 0,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.fetchConfirmedTimeElapsedInCurrentYear:
      return action.paypload;
    default:
      return state;
  }
};
