import { action, ActionType } from "typesafe-actions";
import { CAN_PLAY_TIMER, SET_START_DATE } from "./constants";

export const canSendActivityTime = (ifIsTrueOrFalse: boolean) =>
  action(CAN_PLAY_TIMER, ifIsTrueOrFalse);
export const setStartDate = (date: number) => action(SET_START_DATE, date);
const allActions = {
  canSendActivityTime,
  setStartDate
};

export type activityLessonOrQuizActions = ActionType<typeof allActions>;
