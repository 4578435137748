/** Constante */
import { CONST } from "../config/constant";

/** Models */
import { MessageContact } from "../models/messageContact.model";

/** Model */
import { User } from "../models/user.model";

/** Store */
import { store } from "../store/createStore";
import { throwErrorResponse } from "../helpers/http.helper";

/**
 * Get last module
 */
export const getLastModule = async (fetchWithCredentials: typeof fetch) => {
  /** URL Last module */
  const URL_LAST_MODULE = `${process.env.REACT_APP_API_URL}${CONST.API.LAST_MODULE}`;

  /** Result */
  const result = await fetchWithCredentials(URL_LAST_MODULE, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });

  return await result.json();
};

/**
 * Get time elapsed in current year
 */
export const getTimeElapsedInCurrentYear = async (
  year: number,
  fetchWithCredentials: typeof fetch
) => {
  /** URL time year */
  const URL_TIME_YEAR = `${process.env.REACT_APP_API_URL}${CONST.API.TIME_YEAR}`;

  let result = null;
  try {
    result = await fetchWithCredentials(
      URL_TIME_YEAR.replace(/:year/, year.toString()),
      {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        }
      }
    );
    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};

/**
 * Get certificate list
 */
export const getCertificateList = async (
  fetchWithCredentials: typeof fetch
) => {
  /** URL certificates */
  const URL_CERTIFICATES = `${process.env.REACT_APP_API_URL}${CONST.API.CERTIFICATE_LIST}`;

  /** Result */
  const result = await fetchWithCredentials(URL_CERTIFICATES, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });

  return await result.json();
};

/**
 * Get module activities
 */
export const getModuleActivities = async (
  fetchWithCredentials: typeof fetch,
  moduleId: string = "last"
) => {
  /** URL month module */
  const URL_MONTH_MODULE = `${process.env.REACT_APP_API_URL}${CONST.API.MONTH_MODULE}`;

  let result = null;
  try {
    result = await fetchWithCredentials(
      URL_MONTH_MODULE.replace(/:moduleId/, moduleId),
      {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        }
      }
    );
    if (result?.status != 200) {
      throw result;
    }
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * Get activity
 * @param activityId
 * @param fetchWithCredentials
 */
export const getActivity = async (
  activityId: string,
  fetchWithCredentials: typeof fetch
) => {
  /** URL Activity */
  let URL_ACTIVITY: string = `${process.env.REACT_APP_API_URL}${CONST.API.ACTIVITY}`;
  URL_ACTIVITY = URL_ACTIVITY.replace(/:activityId/, activityId);

  let result = null;
  try {
    result = await fetchWithCredentials(URL_ACTIVITY, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    });
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * Get certificate pdf
 * @param certificateId
 * @param filename
 * @param fetchWithCredentials
 */
export const getCertificatePdf = async (
  certificateId: number,
  filename: string,
  fetchWithCredentials: typeof fetch
) => {
  /** URL download certificate pdf */
  let URL_CERTIFICATE_PDF: string = `${process.env.REACT_APP_API_URL}${CONST.API.CERTIFICATE_PDF}`;

  /** params */
  const params = {
    modules: [certificateId]
  };

  let result = null;
  /** Result */
  try {
    result = await fetchWithCredentials(URL_CERTIFICATE_PDF, {
      method: "post",
      body: JSON.stringify(params),
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/json"
      }
    });

    const url = window.URL.createObjectURL(await result.blob());
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.pdf`);
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (err) {
    result = err.response;
    return false;
  }
};

/**
 * Get certificates files
 * @param certificateIdList
 * @param fetchWithCredentials
 */
export const getCertificatesFiles = async (
  certificateIdList: number[],
  fetchWithCredentials: typeof fetch
) => {
  /** Current user */
  const user: User | null = store.getState().user;
  const fileUsername = `${user!.firstname}_${user!.lastname}`;

  /** URL download certificates */
  const URL_CERTIFICATES_FILES: string = `${process.env.REACT_APP_API_URL}${CONST.API.CERTIFICATE_FILES}`;

  /** params */
  const params = {
    modules: certificateIdList
  };

  let result = null;
  /** Result */
  try {
    result = await fetchWithCredentials(URL_CERTIFICATES_FILES, {
      method: "post",
      body: JSON.stringify(params),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    });

    const url = window.URL.createObjectURL(await result.blob());
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileUsername}_attestations_CAC.zip`);
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (err) {
    result = err.response;
    return false;
  }
};

/**
 * Get all modules
 */
export const getModules = async (fetchWithCredentials: typeof fetch) => {
  /** URL modules */
  const URL_MODULES: string = `${process.env.REACT_APP_API_URL}${CONST.API.MODULES}`;

  let result = null;
  try {
    result = await fetchWithCredentials(URL_MODULES, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    });
    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};

/**
 * Ask module access
 * @param moduleId
 * @param fetchWithCredentials
 */
export const askModuleAccess = async (
  moduleId: number,
  fetchWithCredentials: typeof fetch
) => {
  /** URL Ask module access */
  let URL_ASK_MODULE_ACCESS: string = `${process.env.REACT_APP_API_URL}${CONST.API.MODULES_ACCESS}`;
  URL_ASK_MODULE_ACCESS = URL_ASK_MODULE_ACCESS.replace(
    /:moduleId/,
    moduleId.toString()
  );

  /** params */
  const params = {};

  const result = await fetchWithCredentials(URL_ASK_MODULE_ACCESS, {
    method: "post",
    body: JSON.stringify(params),
    headers: {
      Accept: "application/json"
    }
  });

  return await result.json();
};

/**
 * Refresh cache for a specific module
 * @param moduleId
 * @param fetchWithCredentials
 */
export const moduleCacheRefresh = async (
  moduleId: number,
  fetchWithCredentials: typeof fetch,
  force?: boolean
) => {
  /** URL module cache refresh */
  let URL_MODULE_CACHE_REFRESH: string = `${process.env.REACT_APP_API_URL}${
    CONST.API.MODULE_CACHE_REFRESH
  }${force ? "?force=true" : "?force=false"}`;
  URL_MODULE_CACHE_REFRESH = URL_MODULE_CACHE_REFRESH.replace(
    /:moduleId/,
    moduleId.toString()
  );

  let result = null;
  try {
    result = await fetchWithCredentials(URL_MODULE_CACHE_REFRESH, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    throwErrorResponse(result);

    return await result.json();
  } catch (err) {
    const error = await err.json();
    throw new Error(
      error.data.message ||
        "Une erreur est survenue pendant le rafraichissement du cache."
    );
  }
};

/**
 * Get bilan bloc
 */
export const getBilanBloc = async (
  blocId: number,
  fetchWithCredentials: typeof fetch
) => {
  /** URL get bilan bloc */
  const URL_GET_BILAN_BLOC = `${process.env.REACT_APP_API_URL}${CONST.API.BILAN_BLOC}`;

  let result = null;
  try {
    result = await fetchWithCredentials(
      URL_GET_BILAN_BLOC.replace(/:blocId/, blocId.toString()),
      {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        }
      }
    );
    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};

/**
 * Post activitys valided questions
 * @param fetchCredentials
 * @param activityId
 * @param questionId
 * @param userAnswers
 */
export const postValidedQuestions = async (
  fetchCredentials: typeof fetch,
  activityId: number,
  questionId: number,
  userAnswers: Object
) => {
  /** URL Quiz validated */
  let URL_QUIZ_VALIDED: string = `${process.env.REACT_APP_API_URL}${CONST.API.QUIZ_VALIDED_QUESTION}`;
  URL_QUIZ_VALIDED = URL_QUIZ_VALIDED.replace(
    /:activityId/,
    activityId.toString()
  );
  URL_QUIZ_VALIDED = URL_QUIZ_VALIDED.replace(
    /:questionId/,
    questionId.toString()
  );

  /** params */
  const params = {
    userAnswers
  };

  const result = await fetchCredentials(URL_QUIZ_VALIDED, {
    method: "post",
    body: JSON.stringify(params)
  });

  return await result.json();
};

/**
 * Reset quiz answers
 */
export const resetAnswers = async (
  activityId: number,
  fetchWithCredentials: typeof fetch
) => {
  /** URL reset quiz */
  let URL_RESET_ANSWERS: string = `${process.env.REACT_APP_API_URL}${CONST.API.QUIZ_RESET}`;
  URL_RESET_ANSWERS = URL_RESET_ANSWERS.replace(
    /:activityId/,
    activityId.toString()
  );

  let result = null;
  /** Result */
  try {
    result = await fetchWithCredentials(URL_RESET_ANSWERS, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    });

    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};

/* Post message contact
 * @param message : MessageContact
 */
export const postMessageContact = async (
  message: MessageContact,
  fetchWithCredentials: typeof fetch
): Promise<Response> => {
  /** URL Contact */
  const URL_CONTACT: string = `${process.env.REACT_APP_API_URL}${CONST.API.CONTACT}`;

  return await fetchWithCredentials(URL_CONTACT, {
    method: "post",
    body: JSON.stringify(message),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });
};

/**
 * Retreive asks contact list
 */
export const getAsksContact = async (fetchWithCredentials: typeof fetch) => {
  /** URL subject */
  const URL_SUBJECT: string = `${process.env.REACT_APP_API_URL}${CONST.API.SUBJECT}`;

  let result = null;
  /** Result */
  try {
    result = await fetchWithCredentials(URL_SUBJECT, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    });
    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};
