// React
import React, { useEffect, useState } from "react";
// Material
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Typography
} from "@material-ui/core";
// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { colorCAC } from "../../styles/color";
// Helpers
import { getSecondsFormatted } from "../../helpers/date-fomatter.helper";
import { isPlurial } from "../../helpers/text-formatter.helper";
// Components
import ActivityCard from "../ActivityCard/ActivityCard";
// Models
import { Activity } from "../../models/activity.model";
import { Theme as ThemeCAC } from "../../models/theme.model";
import {
  addActivitySelected,
  setIdBlocSelected
} from "../../store/themeCard/actions";
import { useDispatch } from "react-redux";
import {
  setBlocSelected,
  setModuleActiveWhenBlocSelected,
  setOpenedTheChildModule
} from "../../store/Module/actions";
import clsx from "clsx";
import { disableAutomaticScrolling } from "../../services/browser.service";
//import { setActiveBloc } from "../../store/actions";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 15
    },
    card: {
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
      }
    },
    content: {
      padding: 14,
      "&:last-child": {
        paddingBottom: 14
      }
    },
    title: {
      minHeight: 22,
      fontSize: 16,
      justifyContent: "center",
      fontWeight: 600,
      letterSpacing: 1.07
    },
    subtitle: {
      height: 18,
      letterSpacing: 0.86,
      fontSize: 12,
      justifyContent: "center"
    },
    subtitleTime: {
      color: colorCAC.greenLight
    },
    circleCategory: {
      borderRadius: "50%",
      backgroundColor: colorCAC.grayLight
    },
    isExpandLess: {
      backgroundColor: "#e5e5e5"
    },
    themeItem: {
      [theme.breakpoints.up("md")]: {
        marginTop: 10
      }
    }
  })
);

/**
 * Theme card props
 */
interface IThemeCardProps {
  onLockedClick: () => void;
  isOpen: boolean;
  theme: ThemeCAC;
  expired?: boolean;
  locked?: boolean;
  activeBlocIndex?: number;
  idBlocSelected?: number;
  activePageSelected: string | number;
}

/**
 * Theme card
 */
const ThemeCard = (props: IThemeCardProps) => {
  const classes = useStyles();
  /** UseDispatch */
  const dispatch = useDispatch();
  /** Props */
  const {
    expired,
    theme,
    isOpen,
    activeBlocIndex,
    activePageSelected,
    locked,
    onLockedClick
  } = props;

  /** state variable open */
  const [open, setOpen] = useState<boolean>(isOpen);

  const handlerClick = () => {
    setOpen(!open);
  };
  const setIndexArrayAndIdActivityInStore = (
    index: number,
    idActivity: number | string
  ) => {
    if (undefined !== activeBlocIndex) {
      dispatch(setBlocSelected(activeBlocIndex));
      dispatch(setModuleActiveWhenBlocSelected(activePageSelected));
    }
    dispatch(setOpenedTheChildModule());
    dispatch(addActivitySelected(idActivity as string));
    dispatch(setIdBlocSelected(index));
  };

  /**
   * This useEffect force the mobile to detect the header address bar
   */
  useEffect(() => {
    disableAutomaticScrolling();
  }, [open]);

  return (
    <Grid
      container={true}
      alignItems="center"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid item={true} xs={true}>
        <Card onClick={handlerClick} className={classes.card}>
          <CardContent
            className={clsx({
              [classes.content]: true,
              moduleSelected: true
            })}
          >
            <Grid container={true}>
              <Grid item={true} xs={11}>
                <Grid container={true} direction="row" alignItems="center">
                  <Typography
                    className={classes.title}
                    color="textPrimary"
                    component="span"
                  >
                    {theme.name}
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    color="textSecondary"
                    component="span"
                  >
                    &nbsp;({theme.nbActivities} {isPlurial(theme.nbActivities)}{" "}
                    - {getSecondsFormatted(theme.time.estimated)} -
                  </Typography>
                  <Typography
                    className={`${classes.subtitle} ${classes.subtitleTime}`}
                    component="span"
                  >
                    &nbsp;{theme.time.progress}%
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    color="textSecondary"
                    component="span"
                  >
                    )
                  </Typography>
                </Grid>
              </Grid>
              <Grid item={true} xs={1}>
                <Grid container={true} alignItems="center" justify="center">
                  {open ? (
                    <ExpandLess className={classes.circleCategory} />
                  ) : (
                    <ExpandMore className={classes.circleCategory} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          {theme && theme.activities
            ? theme.activities.map((activity: Activity, index: number) => (
                <Box
                  onClick={() => locked && onLockedClick()}
                  key={activity.id}
                  className={classes.themeItem}
                >
                  <ActivityCard
                    locked={locked}
                    key={activity.uniqueId}
                    activity={activity}
                    approval={theme.approvalType === "H" ? true : false}
                    expired={expired}
                    handleCardSelected={() =>
                      setIndexArrayAndIdActivityInStore(
                        index,
                        activity.uniqueId
                      )
                    }
                  />
                </Box>
              ))
            : null}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default React.memo(ThemeCard);
