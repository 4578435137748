// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

// Material
import { Paper, makeStyles, createStyles, Fab } from "@material-ui/core";

// Models
import { QuizActivity } from "../../models/quiz.model";
import { colorCAC } from "../../styles/color";

// Assets
import landingQuiz1 from "../../assets/landing-starter-quiz-1.svg";
import landingQuiz2 from "../../assets/landing-starter-quiz-2.svg";
import landingQuiz3 from "../../assets/landing-starter-quiz-3.svg";
import { getIndexBlocByName } from "../../helpers/bloc.helper";

const landingQuizSvgs = [landingQuiz1, landingQuiz2, landingQuiz3];

/**
 * Styles
 */
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 40,
      paddingTop: 20,
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse"
      }
    },
    startBtn: {
      background: colorCAC.greenRF,
      color: "#fff",
      fontWeigh: "bold",
      padding: "12px 47px",
      marginTop: 15,
      fontSize: 16
    },
    landingBloc: {
      paddingTop: 20,
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
      [theme.breakpoints.up("md")]: {
        width: "50%"
      }
    },
    mainText: {
      fontSize: 24,
      marginTop: 0
    },
    secondaryText: {
      fontSize: 14
    },
    svg: {
      textAlign: "right",
      mawWidth: "100% !important",
      [theme.breakpoints.down("md")]: {
        textAlign: "center"
      }
    }
  })
);

/** Quiz props */
interface IStarterQuizCardProps {
  quizActivity: QuizActivity;
  blocName: string;
}

/**
 * Quizz component
 */
const StarterQuizCard = ({
  quizActivity,
  blocName,
  match,
  history
}: RouteComponentProps & IStarterQuizCardProps) => {
  const classes = useStyles();
  const landingQuizSvg = landingQuizSvgs[getIndexBlocByName(blocName)];

  return (
    <Paper className={classes.root}>
      <div className={classes.landingBloc}>
        <p className={classes.mainText}>
          Pour accéder à vos activités, commencez par évaluer vos connaissances.
        </p>
        <p className={classes.secondaryText}>Quelques minutes seulement</p>
        <Fab
          variant="extended"
          size="large"
          className={`${classes.startBtn}`}
          onClick={() => {
            history.push({
              pathname: match.url + "/activite/" + quizActivity.uniqueId
            });
          }}
        >
          {quizActivity.validatedQuestionCount
            ? "Terminer mon test"
            : "Démarrer"}
        </Fab>
      </div>
      <div className={`${classes.landingBloc} ${classes.svg}`}>
        <img src={landingQuizSvg} />
      </div>
    </Paper>
  );
};

export default withRouter(React.memo(StarterQuizCard));
