// React
import React from "react";
import { Link } from "react-router-dom";

// Material
import { Fab } from "@material-ui/core";

// Icons
import { ArrowForward } from "@material-ui/icons";

import { useStyles } from "./NextActivity";

interface INextActivityBtnProps {
  nextActivityId?: string | null;
  opts?: {
    shadow?: boolean;
    background?: boolean;
  };
}

const NextActivityBtn = (props: INextActivityBtnProps) => {
  /** Classes  */
  const classes = useStyles();

  /** Props */
  const { nextActivityId } = props;

  const opts = Object.assign({ shadow: true, background: true }, props.opts);

  return (
    <Fab
      component={Link}
      to={window.location.pathname.replace(
        /(quiz|lesson)_\d+/,
        nextActivityId!
      )}
      variant="extended"
      size="large"
      color="primary"
      className={`
        ${classes.button} 
        ${!opts.shadow ? classes.noShadow : ""}
        ${!opts.background ? classes.noBackground : ""}
      `}
      disabled={!nextActivityId}
    >
      Activité suivante&nbsp; <ArrowForward />
    </Fab>
  );
};

export default NextActivityBtn;
