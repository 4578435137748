export const SET_BLOC_SELECTED = "[Module] SET_BLOC_SELECTED";
export const SET_ACTIVE_PAGE_SELECTED = "[Module] SET_ACTIVE_PAGE_SELECTED";
export const SET_HAVE_TO_RESET_VALUE = "[Module] SET_HAVE_TO_RESET_VALUE";
export const UNSET_HAVE_TO_RESET_VALUE = "[Module] UNSET_HAVE_TO_RESET_VALUE";
export const SET_OPENED_THE_CHILD_MODULE =
  "[Module] SET_OPENED_THE_CHILD_MODULE";
export const UNSET_OPENED_THE_CHILD_MODULE =
  "[Module] UNSET_OPENED_THE_CHILD_MODULE";

export const SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED =
  "[Module] SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED";
