import { Action, ActionTypes } from "../../actions";

export const activeBlocReducer = (state: number = 0, action: Action) => {
  switch (action.type) {
    case ActionTypes.fetchActiveBloc:
      return action.paypload;
    default:
      return parseInt(localStorage.getItem("activeBloc") || "0") || state;
  }
};
