import { combineReducers } from "redux";
import { userReducer } from "./modules/user";
import { activeBlocReducer } from "./modules/module";
import { confirmedTimeElapsedInCurrentYearReducer } from "./modules/confirmedTimeElapsedInCurrentYear";

// Model
import { User } from "../../models/user.model";
import { IThemeCard, themeCardReducer } from "../themeCard/reducer";
import { IModule, ModuleReducer } from "../Module/reducer";
import {
  activityLessonOrQuizReducer,
  IActivityLessonOrQuiz
} from "../activityLessonOrQuiz/reducer";
import { errorHandlerReducer, IErrorHandler } from "../errorHandler/reducer";
import { surveyReducer } from "../survey/reducer";

export interface StoreState {
  user: User | null;
  activeBloc: number | 0;
  confirmedTimeElapsedInCurrentYear: number | 0;
  themeCard: IThemeCard;
  moduleCac: IModule;
  ActiveLessonOrQuiz: IActivityLessonOrQuiz;
  errorHandler: IErrorHandler;
  surveyHandler: boolean | null;
}

export const reducers = combineReducers<StoreState>({
  user: userReducer,
  activeBloc: activeBlocReducer,
  confirmedTimeElapsedInCurrentYear: confirmedTimeElapsedInCurrentYearReducer,
  themeCard: themeCardReducer,
  moduleCac: ModuleReducer,
  ActiveLessonOrQuiz: activityLessonOrQuizReducer,
  errorHandler: errorHandlerReducer,
  surveyHandler: surveyReducer
});
