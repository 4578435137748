// React
import React from "react";

// Redux
import { setActiveBloc } from "../../store/actions";

// Material
import { Grid, Tab, Tabs } from "@material-ui/core";
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  Theme
} from "@material-ui/core/styles";

// Images
import iconDossierMois from "../../assets/ic-dossier-mois-small.png";
import iconEnvLegalPro from "../../assets/ic-env-legal-pro-small.png";
import iconPratiqueComptable from "../../assets/ic-pratique-comptable-small.png";

// Components
import ModuleTab from "../ModuleTab/ModuleTab";

// Models
import { Bloc } from "../../models/bloc.model";

/**
 * Styles Material
 */
const themeTabRF = createMuiTheme({
  overrides: {
    MuiTabs: {
      flexContainer: {
        display: "flex",
        justifyContent: "space-between"
      }
    },
    MuiTab: {
      root: {
        padding: 0,
        textTransform: "none"
      },
      textColorInherit: {
        opacity: 1
      },
      wrapper: {
        padding: "13px 14px"
      }
    }
  }
});

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "0 auto"
    },
    tabs: {
      padding: theme.spacing(0.5, 0.5),
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0
    },
    tab: {
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: "none",
      minWidth: "33%",
      flexShrink: 1
    },
    indicatorTab1: {
      backgroundColor: "#f9cc00",
      height: 5
    },
    indicatorTab2: {
      backgroundColor: "#006e49",
      height: 5
    },
    indicatorTab3: {
      backgroundColor: "#82368c",
      height: 5
    },
    activeTab: {
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      boxShadow:
        "0 2px 8px 0 rgba(196, 196, 196, 0.3), 0 -1px 0 0 rgba(0, 0, 0, 0.07)"
    },
    tabsBorder: {
      height: 2,
      borderRadius: "1.5px",
      backgroundColor: "#c3c6cc",
      width: "100%",
      margin: "0 auto",
      marginTop: -8
    }
  })
);

/**
 * ModuleTabs props
 */
interface IModuleTabsProps {
  blockList?: Bloc[];
  isStickyTabs: boolean;
  activePage: number;
}

/**
 * ModuleTabs component
 */
const ModuleTabs = (props: IModuleTabsProps) => {
  /** Classes  */
  const classes = useStyles();

  /** Props */
  const { blockList, isStickyTabs, activePage } = props;

  /**
   * Handle change selected tab
   */
  function handleChange(_event: React.ChangeEvent<{}>, value: number) {
    setActiveBloc(blockList ? blockList[value].id : 0);
  }

  /**
   * Get indicator class name
   */
  function getIndicatorClassName(value: number, blockList: Bloc[] | undefined) {
    let blockName = blockList ? blockList[value].name : "";
    switch (blockName) {
      case "PRATIQUE COMPTABLE":
        return classes.indicatorTab1;
      case "ENVIRONNEMENT LÉGAL ET PROFESSIONNEL":
        return classes.indicatorTab2;
      case "DOSSIER DU MOIS":
        return classes.indicatorTab3;
      default:
        return "";
    }
  }

  /**
   * Get icon from blocname
   */
  function getIconForTab(bloc: Bloc): string {
    switch (bloc.name) {
      case "PRATIQUE COMPTABLE":
        return iconPratiqueComptable;
      case "ENVIRONNEMENT LÉGAL ET PROFESSIONNEL":
        return iconEnvLegalPro;
      case "DOSSIER DU MOIS":
        return iconDossierMois;
      default:
        return "";
    }
  }

  return (
    <MuiThemeProvider theme={themeTabRF}>
      <React.Fragment>
        <div className={isStickyTabs ? classes.container : ""}>
          <Grid container={true} alignItems="center">
            <Grid item={true} xs={true}>
              <Tabs
                value={activePage}
                onChange={handleChange}
                className={classes.tabs}
                classes={{
                  indicator: getIndicatorClassName(activePage, blockList)
                }}
              >
                {blockList
                  ? blockList.map((bloc: Bloc, index: number) => (
                      <Tab
                        key={bloc.id}
                        className={`${classes.tab} ${
                          activePage === index && !isStickyTabs
                            ? classes.activeTab
                            : ""
                        }`}
                        icon={
                          <ModuleTab
                            title={bloc.name}
                            nbActivities={bloc.nbActivities}
                            percentage={bloc.time.progress}
                            icon={
                              isStickyTabs ? undefined : getIconForTab(bloc)
                            }
                          />
                        }
                      />
                    ))
                  : null}
              </Tabs>
            </Grid>
          </Grid>
        </div>
        <div className={classes.tabsBorder} />
      </React.Fragment>
    </MuiThemeProvider>
  );
};

export default React.memo(ModuleTabs);
