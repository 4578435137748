// React
import Carousel from "nuka-carousel";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Animate } from "react-simple-animate";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/reducers";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { setActiveBloc } from "../../store/actions";
// Material
import { Box, Button, Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// Libs
import Helmet from "react-helmet";
// Components
import ModuleExpiredBanner from "../../features/ModuleExpiredBanner/ModuleExpiredBanner";
import ModuleLockedBlocBanner from "../../features/ModuleLockedBlocBanner/ModuleLockedBlocBanner";
import ModuleTab from "../../features/ModuleTab/ModuleTab";
import ModuleTabs from "../../features/ModuleTabs/ModuleTabs";
import NoResult from "../../features/NoResult/NoResult";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
import ThemeCard from "../../features/ThemeCard/ThemeCard";
import StarterQuizCard from "../../features/Quiz/StarterQuizCard";
// Models
import { Bloc } from "../../models/bloc.model";
import { Category } from "../../models/category.model";
import { Theme as ThemeCAC } from "../../models/theme.model";
import { QuizActivity } from "../../models/quiz.model";
// Services
import { getModuleActivities } from "../../services/navigation.service";
// Loader constant
import { CONST_LOADER } from "../../config/constantLoaders";
// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";
// Helper
import { getIndicatorColor } from "../../helpers/bloc.helper";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
import {
  setActivePageSelected,
  setHaveToResetValue
} from "../../store/Module/actions";
import { getModuleValidationFlashMessage } from "../../services/flash.service";
import { useSnackbar } from "notistack";
import { disableAutomaticScrolling } from "../../services/browser.service";
import { RESPONSIVE } from "../../config/responsive";
import { setSurveyChoice } from "../../store/survey/actions";
import { getUserSurvey } from "../../services/user.service";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        marginTop: -40
      }
    },
    header: {
      height: "70px",
      position: "absolute",
      left: 0,
      width: "100%",
      zIndex: 100,
      backgroundColor: "#fcfcfd",
      boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)",
      [theme.breakpoints.up("md")]: {
        marginBottom: "17px"
      }
    },
    headerTitle: {
      color: "#006e49",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      }
    },
    container: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      }
    },
    wrapper: {
      width: "100%"
    },
    tabsContainer: {
      marginTop: 90
    },
    tabs: {
      minWidth: "100%"
    },
    stickyTabs: {
      position: "sticky",
      top: "0",
      zIndex: 3,
      backgroundColor: "white"
    },
    expandableContainer: {
      paddingBottom: 40,
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(2)
      },
      /* ----------- iPhone X ----------- */
      /* Portrait */
      [RESPONSIVE.IPHONE_X.PORTRAIT]: {
        paddingBottom: 180
      },
      /* ----------- iPhone 6+, 7+ and 8+ ----------- */
      /* Portrait */
      [RESPONSIVE.IPHONE_6_PLUS_AND_7_PLUS_AND_8_PLUS.PORTRAIT]: {
        paddingBottom: 180
      }
    },
    carouselHeader: {
      backgroundColor: "#ffffff",
      position: "sticky",
      top: "0",
      zIndex: 3
    },
    carouselMonthTab: {
      paddingTop: theme.spacing(1.5),
      boxShadow: "0px 3px 5px -2px rgba(128,128,128,0.7)"
    },
    indicator: {
      height: 3,
      width: "50%",
      marginTop: 15,
      marginLeft: "auto",
      marginRight: "auto"
    },
    noResult: {
      margin: theme.spacing(2)
    }
  })
);

/**
 * Route props
 */
interface IRouteInfo {
  blocIndex: string;
  moduleId: string;
}

interface IRouteModule extends RouteComponentProps<IRouteInfo> {}

/**
 * ModuleTabs props
 */
interface IModuleProps {
  isStickyTabs: boolean;
  setStickyTabs?: (value: boolean) => void;
}

/**
 * Module Component
 */
const Module = (props: IRouteModule & IModuleProps) => {
  /**
   * Keycloak
   */
  const { fetchWithCredentials } = useUserAuthentication();

  const { match, isStickyTabs, setStickyTabs } = props;

  /** Classes  */
  const classes = useStyles();

  /** loading state */
  const [loading, setLoading] = useState<boolean>(true);

  /** Last module state */
  const [currentModule, setCurrentModule] = useState<Category>();

  /** active bloc */
  const activeBloc = useSelector((state: StoreState) => state.activeBloc);

  const [starterQuiz, setStarterQuiz] = useState<QuizActivity>();

  const [showLockedNotification, setShowLockedNotification] = useState(false);

  /** moduleCac selector */
  const {
    blocSelected,
    activePageSelected,
    openedTheChildModule,
    moduleActiveWhenBlocSelected
  } = useSelector((state: StoreState) => state.moduleCac);

  const getRightIndexSelected = (): number => {
    return activePageSelected === moduleActiveWhenBlocSelected
      ? blocSelected
      : 0;
  };

  /** ThemeCard selector */
  const { activitySelected } = useSelector(
    (state: StoreState) => state.themeCard
  );

  /** Time elapsed in current year state */
  const confirmedTimeElapsedInCurrentYear = useSelector(
    (state: StoreState) => state.confirmedTimeElapsedInCurrentYear
  );

  /** carousel min height */
  const [carouselMinHeight, setCarouselMinHeight] = useState<number>(0);

  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();

  /** Dispatch */
  const dispatch = useDispatch();

  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /** Find a */
  const findActivePage = (blocs: Bloc[], blocId: number): number => {
    const index = blocs.findIndex(bloc => bloc.id === blocId);

    return index >= 0 ? index : 0;
  };

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Set page
   * @param page
   */
  const setPage = (page: number) => {
    dispatch(setActivePageSelected(page));

    const activeBloc = currentModule?.children[page];
    if (activeBloc) {
      setStarterQuiz(getBlocStarterQuiz(activeBloc.id));
      setActiveBloc(activeBloc.id);
    }

    /** update carousel's minimum height to prevent sticky bug */
    const activeSlide = document.getElementsByClassName("slide-visible")[0];
    if (activeSlide) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: 0
      });
      setCarouselMinHeight(activeSlide.clientHeight);
    }
  };

  /**
   * Check module validation notification
   */
  const checkModuleValidationNotification = () => {
    const flashMessageModuleValidation = getModuleValidationFlashMessage();

    if (flashMessageModuleValidation) {
      // Display survey
      getUserSurvey(fetchWithCredentials).then(res => {
        if (res.subscribed === null) {
          dispatch(setSurveyChoice(true));
        }
      });

      // Notification
      const { content, variant, duration } = flashMessageModuleValidation;
      enqueueSnackbar(content, {
        variant,
        autoHideDuration: duration
      });
    }
  };

  /** Use effect */
  useEffect(() => {
    let isSubscribed = true;

    setTimeout(() => {
      const fetchData = async () => {
        const result = match.params.moduleId
          ? await getModuleActivities(
              fetchWithCredentials,
              match.params.moduleId
            ).catch(err => {
              dispatch(setErrorToHandleError(true, err.status));
            })
          : await getModuleActivities(fetchWithCredentials).catch(err => {
              dispatch(setErrorToHandleError(true, err.status));
            });
        setCurrentModule(result?.data);
        setLoading(false);
      };

      if (isSubscribed) {
        fetchData();
      }

      checkModuleValidationNotification();
    }, 0);

    return function cleanup() {
      isSubscribed = false;
    };
  }, [match.url, confirmedTimeElapsedInCurrentYear]);

  useEffect(() => {
    if (currentModule) {
      const page: number = findActivePage(currentModule.children, activeBloc);
      setPage(page);
    }
  }, [activeBloc, currentModule]);

  const getBlocStarterQuiz = (blocId: number): QuizActivity | undefined => {
    return currentModule!.quizzesStarted![blocId];
  };

  /**
   *  this use Effect check if this child element opened and scroll to the last child Module open (activity or quizz)
   */
  useEffect(() => {
    setStickyTabs && setStickyTabs(false);

    setTimeout(() => {
      if (openedTheChildModule && !loading && currentModule) {
        if (activitySelected) {
          const blocOffsetTop =
            window.document.getElementById(activitySelected)?.offsetTop || 0;
          const headerHeight =
            window.document.querySelector("header")?.offsetHeight || 0;
          dispatchScroll({
            type: "setScrollPosition",
            scrollPosition: blocOffsetTop + headerHeight
          });
          disableAutomaticScrolling();
        }
      }
    }, 100);
  }, [!loading && currentModule]);

  return (
    <div className={classes.root}>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>
          {match.params.moduleId && !currentModule
            ? `Module`
            : match.params.moduleId && currentModule
            ? currentModule.name
            : `Module du mois`}{" "}
          - RF e-Learning CAC
        </title>
      </Helmet>
      {!loading &&
      currentModule &&
      currentModule.children.length > 0 &&
      currentModule.children[activePageSelected] ? (
        <React.Fragment>
          {currentModule.expired && <ModuleExpiredBanner />}
          {showLockedNotification && <ModuleLockedBlocBanner />}
          <Hidden lgUp={true}>
            <Grid
              container={true}
              direction="row"
              className={classes.carouselHeader}
            >
              <Box
                m={2}
                fontSize={20}
                textAlign="center"
                fontWeight="fontWeightBold"
                width="100%"
                className={classes.headerTitle}
              >
                Module : {currentModule.name}
              </Box>
            </Grid>
            <Grid
              container={true}
              direction="row"
              className={`${classes.carouselHeader} ${classes.carouselMonthTab}`}
              justify="space-between"
              alignItems="center"
            >
              <Grid
                item={true}
                xs={2}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button
                  size="small"
                  onClick={() => {
                    setPage(
                      activePageSelected === 0
                        ? currentModule.children.length - 1
                        : activePageSelected - 1
                    );
                    dispatch(setHaveToResetValue());
                  }}
                >
                  <KeyboardArrowLeft />
                </Button>
              </Grid>
              <Grid item={true} xs={8}>
                <ModuleTab
                  title={currentModule.children[activePageSelected].name}
                  nbActivities={
                    currentModule.children[activePageSelected].nbActivities
                  }
                  percentage={
                    currentModule.children[activePageSelected].time.progress
                  }
                  mobile={true}
                />
              </Grid>
              <Grid
                item={true}
                xs={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  size="small"
                  onClick={() => {
                    setPage(
                      activePageSelected < currentModule.children.length - 1
                        ? activePageSelected + 1
                        : 0
                    );
                    dispatch(setHaveToResetValue());
                  }}
                >
                  <KeyboardArrowRight />
                </Button>
              </Grid>
              <div
                className={classes.indicator}
                style={getIndicatorColor(
                  currentModule.children[activePageSelected].name
                )}
              />
            </Grid>
            <Carousel
              style={{ minHeight: carouselMinHeight }}
              width={"100%"}
              slidesToShow={1}
              slideIndex={activePageSelected}
              afterSlide={slideIndex => setPage(slideIndex)}
              withoutControls={true}
              wrapAround={true}
              frameOverflow={"visible"}
              heightMode={"current"}
            >
              {currentModule.children
                ? currentModule.children.map((bloc: Bloc) => (
                    <React.Fragment key={bloc.id}>
                      <Box ml={2} mr={2}>
                        {!!getBlocStarterQuiz(bloc.id) && (
                          <StarterQuizCard
                            blocName={bloc.name}
                            quizActivity={
                              getBlocStarterQuiz(bloc.id) as QuizActivity
                            }
                          />
                        )}
                        <Grid
                          container={true}
                          direction="column"
                          justify="space-between"
                          alignItems="flex-start"
                          alignContent="center"
                          className={classes.expandableContainer}
                        >
                          {bloc.children && bloc.children.length > 0 ? (
                            bloc.children.map(
                              (theme: ThemeCAC, index: number) => (
                                <ThemeCard
                                  onLockedClick={activateLockedNotification}
                                  key={theme.id}
                                  isOpen={getRightIndexSelected() === index}
                                  theme={theme}
                                  expired={currentModule.expired}
                                  locked={!!getBlocStarterQuiz(bloc.id)}
                                  activeBlocIndex={index}
                                  idBlocSelected={theme.id}
                                  activePageSelected={activePageSelected}
                                />
                              )
                            )
                          ) : (
                            <div className={classes.noResult}>
                              <NoResult
                                titleCustom=""
                                subTitleCustom="Aucune activité disponible"
                              />
                            </div>
                          )}
                        </Grid>
                      </Box>
                    </React.Fragment>
                  ))
                : null}
            </Carousel>
          </Hidden>
          <Hidden mdDown={true}>
            {isStickyTabs ? (
              <Box className={classes.stickyTabs} id="tabsStickyContainer">
                <Animate
                  play={true}
                  duration={0.15}
                  start={{ opacity: 0 }}
                  end={{ opacity: 1 }}
                >
                  <ModuleTabs
                    blockList={currentModule.children}
                    activePage={activePageSelected}
                    isStickyTabs={isStickyTabs}
                  />
                </Animate>
              </Box>
            ) : null}
            <Grid
              container={true}
              direction="column"
              justify="flex-start"
              alignItems="center"
              className={classes.container}
            >
              <Grid item={true} xs={true} className={classes.wrapper}>
                <React.Fragment>
                  <Grid
                    container={true}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    alignContent="center"
                    className={classes.header}
                  >
                    <Grid item={true} className={classes.headerTitle}>
                      <Box fontSize={26} fontWeight="fontWeightBold">
                        Module : {currentModule.name}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    id="tabsContainer"
                    className={classes.tabsContainer}
                  >
                    <div className={classes.tabs}>
                      <ModuleTabs
                        blockList={currentModule.children}
                        activePage={activePageSelected}
                        isStickyTabs={isStickyTabs}
                      />
                    </div>
                  </Grid>
                  {!!starterQuiz && (
                    <StarterQuizCard
                      blocName={currentModule.children[activePageSelected].name}
                      quizActivity={starterQuiz}
                    />
                  )}
                  <Grid
                    container={true}
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                    alignContent="center"
                    className={classes.expandableContainer}
                  >
                    {currentModule.children[activePageSelected].children &&
                    currentModule.children[activePageSelected].children.length >
                      0 ? (
                      currentModule.children[
                        activePageSelected
                      ].children.map((theme: ThemeCAC, index: number) => (
                        <ThemeCard
                          onLockedClick={activateLockedNotification}
                          key={theme.id}
                          locked={!!starterQuiz}
                          isOpen={getRightIndexSelected() === index}
                          theme={theme}
                          expired={currentModule.expired}
                          activeBlocIndex={index}
                          activePageSelected={activePageSelected}
                        />
                      ))
                    ) : (
                      <div className={classes.noResult}>
                        <NoResult
                          titleCustom=""
                          subTitleCustom="Aucune activité disponible"
                        />
                      </div>
                    )}
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
          </Hidden>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Hidden lgUp={true}>
            {currentModule?.children == [] || currentModule === undefined ? (
              <Grid
                container={true}
                direction="column"
                justify="space-between"
                alignItems="flex-start"
                alignContent="center"
                style={{ padding: 16 }}
              >
                <SkeletonLoader
                  height={880}
                  loader={CONST_LOADER.MONTH_MODULE_MOBILE}
                />
              </Grid>
            ) : (
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                Aucune activité disponible
              </Box>
            )}
          </Hidden>
          {currentModule?.children == [] || currentModule === undefined ? (
            <Hidden mdDown={true}>
              <Grid
                container={true}
                spacing={3}
                direction="column"
                justify="flex-start"
                alignItems="center"
                className={classes.container}
              >
                <Grid item={true} xs={true} className={classes.wrapper}>
                  <Grid
                    container={true}
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.tabs}>
                      <SkeletonLoader
                        height={150}
                        loader={CONST_LOADER.MODULE_TABS}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container={true}
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                    alignContent="center"
                    className={classes.expandableContainer}
                  >
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                    <SkeletonLoader
                      height={242}
                      loader={CONST_LOADER.MODULE_CONTENT}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          ) : (
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              Aucune activité disponible
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(React.memo(Module));
