// React
import React from "react";
import NextActivityBtn from "./NextActivityBtn";

// Material
import { Fab, Grid, Hidden, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Icons
import { Refresh, ArrowForward } from "@material-ui/icons";

// Colors
import { colorCAC } from "../../styles/color";

/**
 * Styles
 */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "white",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        margin: 10
      }
    },
    buttonRetry: {
      color: colorCAC.greenRF,
      backgroundColor: "white"
    },
    noShadow: {
      boxShadow: "none"
    },
    noBackground: {
      background: "none !important"
    },
    pagerContainer: {
      width: 960,
      maxWidth: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {}
    },
    pagerNavigation: {
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      },
      [theme.breakpoints.up("md")]: {
        transform: "translateY(-50%)"
      }
    }
  })
);

/**
 * NextActivity props
 */
interface INextActivityProps {
  nextActivityId?: string | null;
  retry?: () => void;
  handleCloseLesson?: () => void;
}

/**
 * NextActivity component
 */
const NextActivity = (props: INextActivityProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Props */
  const { nextActivityId, retry, handleCloseLesson } = props;

  return (
    <div className={classes.pagerContainer}>
      <Grid item={true} xs={true} className={classes.pagerNavigation}>
        <Hidden mdUp={true}>
          <Grid
            container={true}
            justify="center"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            {handleCloseLesson && (
              <Grid
                item={true}
                container={true}
                xs={6}
                alignItems="center"
                justify="center"
                style={{ backgroundColor: "white" }}
              >
                <Button color="primary" onClick={handleCloseLesson}>
                  &nbsp;Démarrer ma formation <ArrowForward />
                </Button>
              </Grid>
            )}
            {retry && (
              <Grid
                item={true}
                container={true}
                xs={6}
                alignItems="center"
                justify="center"
                style={{ backgroundColor: "white" }}
              >
                <Button
                  className={`${classes.button} ${classes.buttonRetry}`}
                  onClick={() => retry()}
                >
                  <Refresh />
                  &nbsp;Recommencer
                </Button>
              </Grid>
            )}
            {nextActivityId !== undefined && (
              <Grid
                item={true}
                container={true}
                xs={6}
                alignItems="center"
                justify="center"
                style={{
                  backgroundColor: nextActivityId
                    ? colorCAC.greenRF
                    : colorCAC.grayLight
                }}
              >
                <NextActivityBtn
                  opts={{ shadow: false, background: false }}
                  nextActivityId={nextActivityId}
                />
              </Grid>
            )}
          </Grid>
        </Hidden>

        <Hidden smDown={true}>
          <Grid container={true} justify="center" alignItems="center">
            {handleCloseLesson && (
              <Fab
                variant="extended"
                size="large"
                color="primary"
                className={classes.button}
                onClick={handleCloseLesson}
              >
                &nbsp;Démarrer ma formation <ArrowForward />
              </Fab>
            )}
            {retry && (
              <Fab
                variant="extended"
                size="large"
                className={`${classes.button} ${classes.buttonRetry}`}
                onClick={() => retry()}
              >
                <Refresh />
                &nbsp;Recommencer
              </Fab>
            )}
            {nextActivityId !== undefined && (
              <NextActivityBtn nextActivityId={nextActivityId} />
            )}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default React.memo(NextActivity);
