import {
  FetchActiveBloc,
  FetchUser,
  FetchConfirmedTimeElapsedInCurrentYear
} from "../actions";

export enum ActionTypes {
  fetchUser,
  fetchActiveBloc,
  fetchConfirmedTimeElapsedInCurrentYear
}

export type Action =
  | FetchUser
  | FetchActiveBloc
  | FetchConfirmedTimeElapsedInCurrentYear;
// this along with the enum
// ex.: export type Action = FetchHomeActivityList | AnotherFetchActivity;
