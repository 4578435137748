// React
import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

// Material
import { Container, Paper, makeStyles, createStyles } from "@material-ui/core";

// Components
import { QuizPlayer } from "@dsk-lib/quizplayer";
import Bilan from "./Bilan/Bilan";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

// Styles
import "./Quiz.scss";
import { colorCAC } from "../../styles/color";

// Models
import { QuizActivity, QuestionValidation } from "../../models/quiz.model";

// Services
import {
  getActivity,
  postValidedQuestions
} from "../../services/navigation.service";
import { setModuleValidationFlashMessage } from "../../services/flash.service";

// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";

// Store
import { useDispatch } from "react-redux";
import { increaseConfirmedTimeElapsedInCurrentYear } from "../../store/actions";

// Config
import { CONST_LOADER } from "../../config/constantLoaders";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    disable: {
      opacity: 0.3,
      cursor: "wait !important"
    }
  })
);

/** Quiz props */
interface IQuizProps {
  quizActivity: QuizActivity;
  handleCloseLesson: () => void;
}

/**
 * Quizz component
 */
const Quiz = ({
  quizActivity,
  handleCloseLesson
}: IQuizProps & RouteComponentProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();

  /** Activity */
  const [activity, setActivity] = useState<QuizActivity>(quizActivity);

  /** Is bilan showing */
  const [isShowBilan, setShowBilan] = useState<any>(
    activity.validatedQuestionCount === activity.questionCount
  );

  /** Loading bilan state */
  const [isLoadingBilan, setLoadingBilan] = useState(false);

  /** Loading question validation */
  const [isLoadingQuestionValidation, setLoadingQuestionValidation] = useState(
    false
  );

  const lastQuestionId: number =
    activity && activity.questions.length
      ? activity.questions[activity.questions.length - 1].id
      : 0;

  const quizPlayQuestionCorrection = (quizPlayerCallback: any) => {
    sendQuestionId(quizPlayerCallback);
  };

  const classes = useStyles();

  /** Use dispatch */
  const dispatch = useDispatch();

  /** Use effect attach event to check when an answer is valided */
  useEffect(() => {
    window.document.addEventListener(
      "QUIZ_PLAYER_QUESTION_CORRECTION",
      quizPlayQuestionCorrection
    );

    return () => {
      window.document.removeEventListener(
        "QUIZ_PLAYER_QUESTION_CORRECTION",
        quizPlayQuestionCorrection
      );
    };
  }, []);

  /** Use effect loading bilan */
  useEffect(() => {
    if (!isShowBilan) {
      setLoadingBilan(false);
      if (activity.validatedQuestionCount === activity.questionCount) {
        setLoadingBilan(true);
        window.location.reload(); // Temporary fix
      }
    }
  }, [isShowBilan]);

  /**
   * handle end of a quiz
   */
  const handleEndQuiz = async () => {
    if (!isLoadingBilan) {
      setLoadingBilan(true);
      await getActivity(activity.uniqueId, fetchWithCredentials).then(
        resultActivity => {
          setActivity(resultActivity.data);
          setShowBilan(true);
        }
      );
    }
  };

  /** Use effect loading next btn step */
  useEffect(() => {
    const btnNext = window.document.querySelector(".btn-next");
    btnNext &&
      btnNext.classList.toggle(classes.disable, isLoadingQuestionValidation);
  }, [isLoadingQuestionValidation]);

  /**
   * sendQuestionId
   * @param quizPlayerDetails
   */
  const sendQuestionId = async (quizPlayerDetails: any) => {
    const questionId = quizPlayerDetails.detail.questionId;
    if (!isLoadingQuestionValidation) {
      setLoadingQuestionValidation(true);
      const result: QuestionValidation = (
        await postValidedQuestions(
          fetchWithCredentials,
          activity.id,
          +questionId,
          quizPlayerDetails.detail.userAnswer
        )
      ).data;

      if (
        !activity.expired &&
        lastQuestionId === questionId &&
        result.module &&
        !activity.started
      ) {
        if (result.module.completed) {
          setModuleValidationFlashMessage(result.module.name);
        }

        dispatch(
          increaseConfirmedTimeElapsedInCurrentYear(activity.time.estimated)
        );
      }

      setLoadingQuestionValidation(false);
    }
  };

  return (
    <Container maxWidth="md">
      <div className="quiz-content">
        {isShowBilan ? (
          <Bilan
            handleCloseLesson={handleCloseLesson}
            activity={activity}
            blocId={activity.blocId}
            setShowBilan={setShowBilan}
          />
        ) : isLoadingBilan ? (
          <SkeletonLoader height={880} loader={CONST_LOADER.QUIZZ_BILAN} />
        ) : (
          <React.Fragment>
            {(activity.type === "analyse" ||
              activity.type === "categorisation") && (
              <h2
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  margin: 0,
                  color:
                    activity.type === "analyse"
                      ? colorCAC.purple
                      : activity.type === "categorisation"
                      ? colorCAC.greenLight
                      : colorCAC.black
                }}
              >
                CONSIGNE
              </h2>
            )}
            <QuizPlayer
              currentQuestion={activity.validatedQuestionCount + 1}
              handleEndQuiz={handleEndQuiz}
              content={activity as any}
              correctionStepByStep={true}
              id={`quiz-${activity.type}`}
              requiredAnswer={true}
              showCorrection={false}
              showFinalScore={false}
            />
          </React.Fragment>
        )}
      </div>
      {!isShowBilan && (
        <Paper
          elevation={3}
          style={{
            position:
              navigator.platform === "iPad" || "iPhone" ? "fixed" : "absolute",
            bottom: 0,
            height: "70px",
            left: 0,
            right: 0
          }}
        />
      )}
    </Container>
  );
};

export default withRouter(React.memo(Quiz));
