import { action, ActionType } from "typesafe-actions";
import {
  SET_OPENED_THE_CHILD_MODULE,
  SET_ACTIVE_PAGE_SELECTED,
  SET_BLOC_SELECTED,
  SET_HAVE_TO_RESET_VALUE,
  UNSET_HAVE_TO_RESET_VALUE,
  UNSET_OPENED_THE_CHILD_MODULE,
  SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED
} from "./types";
import { setItemLocalStorage } from "../../services/utility.service";

export const setBlocSelected = (indexBlocSelected: number) => {
  setItemLocalStorage(SET_BLOC_SELECTED, indexBlocSelected);
  return action(SET_BLOC_SELECTED, indexBlocSelected);
};

export const setHaveToResetValue = () => {
  setItemLocalStorage(SET_HAVE_TO_RESET_VALUE, true);
  return action(SET_HAVE_TO_RESET_VALUE, true);
};
export const unsetHaveToResetValue = () => {
  setItemLocalStorage(UNSET_HAVE_TO_RESET_VALUE, false);
  return action(UNSET_HAVE_TO_RESET_VALUE, false);
};

export const setOpenedTheChildModule = () => {
  setItemLocalStorage(SET_OPENED_THE_CHILD_MODULE, true);
  return action(SET_OPENED_THE_CHILD_MODULE, true);
};

export const unsetOpenedTheChildModule = () => {
  setItemLocalStorage(UNSET_OPENED_THE_CHILD_MODULE, false);
  return action(UNSET_OPENED_THE_CHILD_MODULE, false);
};

export const setActivePageSelected = (indexActivePage: number) => {
  setItemLocalStorage(SET_ACTIVE_PAGE_SELECTED, indexActivePage);
  return action(SET_ACTIVE_PAGE_SELECTED, indexActivePage);
};

export const setModuleActiveWhenBlocSelected = (
  idModuleActive: number | string
) => {
  setItemLocalStorage(SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED, idModuleActive);
  return action(SET_MODULE_ACTIVE_WHEN_BLOC_SELECTED, idModuleActive);
};

const allActions = {
  setBlocSelected,
  setActivePageSelected,
  setHaveToResetValue,
  unsetHaveToResetValue,
  setOpenedTheChildModule,
  unsetOpenedTheChildModule,
  setModuleActiveWhenBlocSelected
};

export type BlocAction = ActionType<typeof allActions>;
