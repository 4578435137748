/**
 * Add necessary HTML class to add CSS (see activity.scss for more detail)
 * @param activity
 */
export const formatLessonSummaryContent = (summaryContent: string): string => {
  const parser = new DOMParser();
  const el = parser.parseFromString(summaryContent, "text/html")
    .documentElement;

  const item = el.getElementsByTagName("div")[0];
  item.classList.add("item");

  const summary = el.getElementsByTagName("div")[1];
  summary.classList.add("summary");

  const content = el.getElementsByTagName("div")[2];
  content.classList.add("content");

  return el.querySelector("body")!.innerHTML;
};

/**
 * Is lesson
 * @param uniqueId
 */
export const isLesson = (uniqueId: any): boolean => {
  return checkActivityType(uniqueId, "lesson");
};

/**
 * Is quiz
 * @param uniqueId
 */
export const isQuiz = (uniqueId: any): boolean => {
  return checkActivityType(uniqueId, "quiz");
};

/**
 * Check activity type
 * @param uniqueId
 * @param type
 */
export const checkActivityType = (
  uniqueId: any,
  type: "lesson" | "quiz"
): boolean => {
  if (typeof uniqueId !== "string") {
    return false;
  }
  return uniqueId.substring(0, uniqueId.indexOf("_")) === type;
};

/**
 * Is quiz final
 * @param type
 */
export const isQuizFinal = (type: string | null): boolean => {
  return "quizFinal" === type;
};
