import { Reducer } from "redux";
import { themcardAction } from "./actions";
import { ADD_ACTIVITY_SELECTED, SET_ID_BLOC_SELECTED } from "./types";
import { getItemLocalStorage } from "../../services/utility.service";

export interface IThemeCard {
  activitySelected: string | null;
  idBlocSelected: string | null;
}

const initialState: IThemeCard = {
  activitySelected: getItemLocalStorage(ADD_ACTIVITY_SELECTED) || null,
  idBlocSelected: getItemLocalStorage(SET_ID_BLOC_SELECTED) || null
};

const reducer: Reducer<any, themcardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_ACTIVITY_SELECTED:
      return {
        ...state,
        activitySelected: action.payload
      };
    case SET_ID_BLOC_SELECTED:
      return {
        ...state,
        idBlocSelected: action.payload
      };
    default:
      return state;
  }
};

export { reducer as themeCardReducer };
