// React
import React, { useEffect, useRef, useState } from "react";
// moment
import moment from "moment";
// material UI
import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// variable
import { colorCAC } from "../../styles/color";
// store
import { useDispatch } from "react-redux";
import { setStartDate } from "../../store/activityLessonOrQuiz/action";
import { store } from "../../store/createStore";

/**
 * Interface Timer
 */
interface ITimer {
  over: boolean;
  counters: number;
  activityTime: number;
  isResponsive: boolean;
  sendActiveTime: (startDate: number) => void;
  canPlayTimer: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      borderRadius: 20,
      width: 105,
      backgroundColor: "white"
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    txtFinished: {
      color: colorCAC.greenRF
    },
    icFinished: {
      color: colorCAC.greenRF
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

const Timer = ({
  over,
  counters,
  activityTime,
  isResponsive,
  sendActiveTime,
  canPlayTimer
}: ITimer) => {
  /** Classes  **/
  const classes = useStyles();

  /** Finished */
  const [counterFinish, setCounterFinish] = useState<boolean>(
    counters >= activityTime
  );

  /** Counter */
  const [counter, setCounter] = useState<number>(
    counterFinish ? activityTime : counters
  );

  /** Sending time interval in seconds */
  const sendingTimeInterval = 60;

  const timerInterval = useRef<null | NodeJS.Timeout>(null);

  /**
   * current Time with format for client
   * @type {string}
   */
  const currentTimeFormat = moment.utc(counter * 1000).format("HH:mm:ss");

  /**
   * Dispatch Hook for dispatch
   * @type {Dispatch<any>}
   */
  const dispatch = useDispatch();

  const stop = () => {
    timerInterval && clearInterval(timerInterval.current!);
  };

  /**
   * Use effect to completed max time
   */
  useEffect(() => {
    if (over) {
      sendActiveTime(Date.now() - activityTime * 1000);
      setCounter(activityTime);
      stop();
    }
  }, [over]);

  /**
   * startCounter
   */
  const startCounter = () => {
    if (!counterFinish) {
      dispatch(setStartDate(Date.now()));
      timerInterval.current = setInterval(() => {
        setCounter(counter => {
          return counter + 1;
        });
      }, 1000);
    }
  };

  /**
   * cleanup
   */
  const cleanup = () => {
    if (store.getState().ActiveLessonOrQuiz.startDate != 0) {
      sendActiveTime(store.getState().ActiveLessonOrQuiz.startDate);
      dispatch(setStartDate(0));
      stop();
    }
  };

  /**
   * Use effect send time by regular interval
   */
  useEffect(() => {
    if (
      store.getState().ActiveLessonOrQuiz.startDate &&
      counter >= sendingTimeInterval
    ) {
      const now = Date.now();
      sendActiveTime(store.getState().ActiveLessonOrQuiz.startDate);
      dispatch(setStartDate(now));
    }
  }, [Math.ceil(counter / sendingTimeInterval)]);

  /**
   * Use effect init start date when timer is started or finished
   */
  useEffect(() => {
    canPlayTimer ? startCounter() : cleanup();
  }, [canPlayTimer]);

  /**
   * Use effect when leaving page where timer is displayed
   */
  useEffect(() => {
    return cleanup;
  }, []);

  /**
   * Use effect checked if timer is finished
   */
  useEffect(() => {
    if (counter >= activityTime) {
      setCounterFinish(true);
      cleanup();
    }
  }, [counter]);

  const iconValidity = (
    <CheckCircleIcon
      className={counterFinish ? classes.icFinished : classes.icNotFinished}
    />
  );

  return !isResponsive ? (
    <Chip
      label={currentTimeFormat}
      className={`${classes.btgroup} ${classes.chip} ${
        counterFinish ? classes.txtFinished : ""
      }`}
      variant="outlined"
      onDelete={() => {
        return;
      }}
      deleteIcon={iconValidity}
    />
  ) : (
    <Box m={2} style={{ display: "flex" }}>
      <Typography
        variant="body1"
        gutterBottom={true}
        className={`${counterFinish ? classes.txtFinished : ""}`}
      >
        {currentTimeFormat}
      </Typography>
      <CheckCircleIcon
        className={counterFinish ? classes.icFinished : classes.icNotFinished}
      />
    </Box>
  );
};

export default React.memo(Timer);
