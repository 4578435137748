// React
import React, { useEffect, useState } from "react";
// Material
import { Box, Paper, Theme, Typography } from "@material-ui/core";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
import { colorCAC } from "../../../styles/color";
import { handleBilanColor } from "../../../helpers/color-translator.helper";
import iconProblem from "../../../assets/icon-problem.svg";
// Components
import NextActivity from "../../NextActivity/NextActivity";
import QuizAnswerProgress from "./QuizAnswerProgress/QuizAnswerProgress";
import BilanThemeCard from "./BilanThemeCard/BilanThemeCard";
// Models
import { IQuestionReport, QuizActivity } from "../../../models/quiz.model";
// Services
import { resetAnswers } from "../../../services/navigation.service";
// Keycloak
import { useUserAuthentication } from "@dsk-lib/user";
// Helpers
import { isQuizFinal } from "../../../helpers/activity.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bilanHeaderTitle: {
      fontSize: 18,
      lineHeight: 1.5,
      paddingTop: 12,
      marginTop: -25,
      zIndex: 3,
      backgroundColor: colorCAC.grayLight,
      fontWeight: "bold",
      position: "relative"
    },
    bilanStartedValidatedScoreTitle: {
      fontSize: 14,
      color: "#828999",
      fontWeight: "bold",
      marginTop: 0
    },
    themeLabel: {
      fontWeight: "bold",
      display: "inline-block",
      marginRight: 15,
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    iconProblem: {
      marginBottom: theme.spacing(5),
      width: 223,
      height: 60,
      objectFit: "contain"
    }
  })
);

/**
 * Bilan props
 */
interface IBilanProps {
  activity: QuizActivity;
  blocId: number;
  setShowBilan: (arg: boolean) => void;
  handleCloseLesson: () => void;
}

/**
 * Bilan Component
 */
const Bilan = ({ activity, setShowBilan, handleCloseLesson }: IBilanProps) => {
  /** Keycloak */
  const { fetchWithCredentials } = useUserAuthentication();
  /** classes */
  const classes = useStyles();
  /** Error */
  const [hasError, setHasError] = useState(false);

  const formatCorrectAnswerPercentage = (validatedScore: number): number => {
    return parseInt(((100 * validatedScore) / activity.totalScore).toFixed(0));
  };

  /** Correct answer percentage */
  const correctAnswerPercentage = formatCorrectAnswerPercentage(
    activity.validatedScore
  );

  const diffWithStartedValidatedScore =
    correctAnswerPercentage -
    formatCorrectAnswerPercentage(activity.startedValidatedScore || 0);

  /** Reset */
  const reset = async () => {
    const quizStatusTmp = quizStatus;
    delete quizStatusTmp[activity.id];
    window.localStorage.setItem(
      `${window.location.host}-quiz`,
      JSON.stringify(quizStatusTmp)
    );

    await resetAnswers(activity.id, fetchWithCredentials);
  };

  /** Retry */
  const retry = async () => {
    await reset();

    setShowBilan(false);
  };

  /** Reload */
  const reload = () => {
    window.location.reload();
  };

  /** Init quizStatus */
  const init = () => {
    const quiz = JSON.parse(
      window.localStorage.getItem(`${window.location.host}-quiz`) || "{}"
    );
    if (quiz) {
      return quiz;
    }
  };

  useEffect(() => {
    if (!activity.validatedQuestionCount) {
      retry();
    } else if (
      activity.validatedQuestionCount < (activity.questionCount ?? 0)
    ) {
      // One or more question validation hasn't been saved correctly
      setHasError(true);
      reset();
    }
  }, [activity]);

  /** quizStatus */
  const quizStatus = init();

  const started = activity.started;

  if (hasError) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img className={classes.iconProblem} src={iconProblem} alt="Erreur" />
          <Typography
            component="p"
            align="center"
            className={classes.bilanHeaderTitle}
          >
            Malheureusement, la connexion au serveur a été interrompue, nous
            n'avons pas pu enregistrer vos réponses. Cliquez ci-dessous pour
            recommencer le quiz.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          style={{
            position:
              navigator.platform === "iPad" || "iPhone" ? "fixed" : "absolute",
            bottom: 0,
            height: "70px",
            left: 0,
            right: 0
          }}
        >
          <NextActivity retry={reload} />
        </Paper>
      </>
    );
  }

  return (
    <>
      {activity.validatedQuestionCount ? (
        <>
          <QuizAnswerProgress progress={+correctAnswerPercentage} />
          <Typography
            component="p"
            align="center"
            className={classes.bilanHeaderTitle}
            style={{
              color: handleBilanColor(+correctAnswerPercentage)
            }}
          >
            Vous avez eu {correctAnswerPercentage}% de bonnes réponses
          </Typography>
          {isQuizFinal(activity.type) && (
            <>
              {activity.startedValidatedScore !== null && (
                <Typography
                  component="p"
                  align="center"
                  className={classes.bilanStartedValidatedScoreTitle}
                >
                  {diffWithStartedValidatedScore > 0 && "+"}
                  {diffWithStartedValidatedScore}% par rapport au test de
                  positionnement
                </Typography>
              )}
              <div style={{ paddingBottom: 30, marginTop: 30 }}>
                {activity.report.map((themeBilan: IQuestionReport, index) => (
                  <BilanThemeCard
                    index={++index}
                    key={index}
                    label={themeBilan.label}
                    progress={themeBilan.progress}
                  />
                ))}
              </div>
            </>
          )}
          <Paper
            elevation={3}
            style={{
              position:
                navigator.platform === "iPad" || "iPhone"
                  ? "fixed"
                  : "absolute",
              bottom: 0,
              height: "70px",
              left: 0,
              right: 0
            }}
          >
            <NextActivity
              handleCloseLesson={started ? handleCloseLesson : undefined}
              retry={!started ? retry : undefined}
              nextActivityId={!started ? activity.nextActivityId : undefined}
            />
          </Paper>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(Bilan);
